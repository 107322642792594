import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { validateEmail } from 'utils/validators'
import { WrapComponentWithAppStateConsumer } from 'AppContext'
import { Button, FieldText, Typography } from '@ahazou/react-components'
import style from './style.module.css'

const FormRecoveryPassword = ({ onSubmit }) => {
  const [isFormFilled, setIsFormFilled] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')

  const [buttonLabel, setButtonLabel] = useState('')
  const [buttonIcon, setButtonIcon] = useState(null)
  const [buttonDisabled, setButtonDisabled] = useState(true)

  const fieldsMap = {
    email: { setValue: setEmail, setError: setEmailError },
  }

  const handleFieldChange = (e) => {
    const { name: fieldName } = e.target
    const { value: fieldValue } = e.target

    fieldsMap[fieldName].setValue(fieldValue)
    fieldsMap[fieldName].setError('')
  }

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()

      if (isSending || isSuccess) {
        return
      }

      if (!validateEmail(email)) {
        setEmailError('o email é inválido')
        return
      }

      setIsSending(true)

      const response = await onSubmit({
        email,
      })

      setIsSending(false)

      if (response.success) {
        setIsSuccess(true)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [email, isSending, isSending],
  )

  useEffect(() => {
    const isFilled = email

    setIsFormFilled(isFilled)
  }, [email])

  useEffect(() => {
    if (isSuccess) {
      setButtonIcon('mono_check_filled')
      setButtonLabel('pronto')
      setButtonDisabled(false)
    } else if (isSending) {
      setButtonLabel('processando')
      setButtonDisabled(false)
    } else if (isFormFilled) {
      setButtonIcon(null)
      setButtonLabel('recuperar')
      setButtonDisabled(false)
    } else {
      setButtonIcon(null)
      setButtonLabel('recuperar')
      setButtonDisabled(true)
    }
  }, [isFormFilled, isSending, isSuccess])

  return (
    <div className={style.container}>
      <Typography className={style.title} variant="displayM">
        recuperar senha
      </Typography>
      <form className={style.form} onSubmit={handleSubmit}>
        <FieldText
          className={style.field}
          name="email"
          type="email"
          value={email}
          readOnly={isSending || isSuccess}
          error={emailError}
          onChange={handleFieldChange}
          label="Email cadastrado"
          hint="digite o email cadastrado para recuperar sua senha"
        />

        <Button
          variant="primaryGradient"
          type="submit"
          className={style.button}
          disabled={buttonDisabled}
          label={buttonLabel}
          prefixIcon={buttonIcon}
          loading={isSending}
        />
      </form>
    </div>
  )
}

FormRecoveryPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default WrapComponentWithAppStateConsumer(FormRecoveryPassword)
