/* eslint-disable max-len */
import { isCpf, isCnpj } from 'validator-brazil'

export function validateEmail(email) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  )
}

export function validateCpf(cpf) {
  return isCpf(cpf)
}

export function validateCnpj(cnpj) {
  return isCnpj(cnpj)
}

export function validateCEP(cep) {
  return /^[0-9]{5}-[0-9]{3}$/.test(cep)
}
