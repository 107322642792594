import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import msk from 'msk'
import creditCardType from 'credit-card-type'
import creditCardsImages from 'utils/credit-cards-images'
import { validateCnpj, validateCpf, validateEmail } from 'utils/validators'
import {
  Button,
  CardClean,
  FieldSelect,
  FieldText,
  Logo,
  Typography,
} from '@ahazou/react-components'
import style from './style.module.css'

const FormSubscriptionCreditCard = ({
  onSubmit,
  pixSlipOnClick,
  title,
  labelButton,
  initialValues,
}) => {
  const [isFormFilled, setIsFormFilled] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [name, setName] = useState(initialValues.name)
  const [plan] = useState(initialValues.plan)
  const [email, setEmail] = useState(initialValues.email)
  const [number, setNumber] = useState('')
  const [expiry, setExpiry] = useState('')
  const [cvc, setCvc] = useState('')
  const [months, setMonth] = useState('')
  const [document, setDocument] = useState('')

  const [nameError, setNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [documentError, setDocumentError] = useState('')
  const [expiryError, setExpiryError] = useState('')
  const [cvcError, setCvcError] = useState('')
  const [monthError, setMonthError] = useState('')
  const [numberError, setNumberError] = useState('')

  const [buttonLabel, setButtonLabel] = useState('')
  const [buttonIcon, setButtonIcon] = useState(null)
  const [buttonDisabled, setButtonDisabled] = useState(true)

  const [value] = useState(null)

  const paymentMethod = 'credit_card'

  const fieldsMap = {
    name: { setValue: setName, setError: setNameError },
    email: { setValue: setEmail, setError: setEmailError },
    number: { setValue: setNumber, setError: setNumberError },
    expiry: { setValue: setExpiry, setError: setExpiryError },
    cvc: { setValue: setCvc, setError: setCvcError },
    months: { setValue: setMonth, setError: setMonthError },
    document: { setValue: setDocument, setError: setDocumentError },
  }

  const getBrandByNumber = () => {
    const creditCard = number ? creditCardType(number) : []
    return creditCard[0] ? creditCard[0].type : 'placeholder'
  }

  const handleFieldChange = (e) => {
    const { name: fieldName } = e.target
    let { value: fieldValue } = e.target

    const masksMap = {
      number: '9999 9999 9999 9999',
      expiry: '99 / 99',
      cvc: '9999',
      cpf: '999.999.999-99',
      cnpj: '99.999.999/9999-99',
    }

    // generic masks
    if (masksMap[fieldName]) {
      fieldValue = msk.fit(fieldValue, masksMap[fieldName])
    }

    // document mask
    if (fieldName === 'document') {
      fieldValue =
        fieldValue.length > 14 ?
          msk.fit(fieldValue, masksMap.cnpj) :
          msk.fit(fieldValue, masksMap.cpf)
    }

    fieldsMap[fieldName].setValue(fieldValue)
    fieldsMap[fieldName].setError('')
  }

  const handleChange = ({ selected }) => {
    fieldsMap.months.setValue(selected.value)
  }

  const calcValueParc = (parc) => {
    let valuePlan

    if (plan === process.env.REACT_APP_PLAN_ANUAL) {
      valuePlan = 199.9
    } else if (plan === process.env.REACT_APP_PLAN_ANUAL_PRO) {
      valuePlan = 299.9
    }

    let valueTotal
    if (parc === 1) {
      valueTotal = valuePlan
    } else if (parc === 2) {
      valueTotal = valuePlan / parc
    } else if (parc === 3) {
      valueTotal = valuePlan / parc
    } else if (parc === 4) {
      valueTotal = valuePlan / parc
    } else if (parc === 5) {
      valueTotal = valuePlan / parc
    } else if (parc === 6) {
      valueTotal = valuePlan / parc
    } else if (parc === 7) {
      valueTotal = valuePlan / parc
    } else if (parc === 8) {
      valueTotal = valuePlan / parc
    } else if (parc === 9) {
      valueTotal = valuePlan / parc
    } else if (parc === 10) {
      valueTotal = valuePlan / parc
    } else if (parc === 11) {
      valueTotal = valuePlan / parc
    } else if (parc === 12) {
      valueTotal = valuePlan / parc
    }

    const formatValueTotal = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(valueTotal)

    return parc === 1 ?
      `${parc}x de R$ ${formatValueTotal} (à vista)` :
      `${parc}x de R$ ${formatValueTotal}`
  }

  const options = [
    {
      label: calcValueParc(1),
      value: '1',
    },
    {
      label: calcValueParc(2),
      value: '2',
    },
    {
      label: calcValueParc(3),
      value: '3',
    },
    {
      label: calcValueParc(4),
      value: '4',
    },
    {
      label: calcValueParc(5),
      value: '5',
    },
    {
      label: calcValueParc(6),
      value: '6',
    },
    {
      label: calcValueParc(7),
      value: '7',
    },
    {
      label: calcValueParc(8),
      value: '8',
    },
    {
      label: calcValueParc(9),
      value: '9',
    },
    {
      label: calcValueParc(10),
      value: '10',
    },
    {
      label: calcValueParc(11),
      value: '11',
    },
    {
      label: calcValueParc(12),
      value: '12',
    },
  ]

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()

      if (isSending || isSuccess) {
        return
      }

      if (!name) {
        setNameError('é necessário informar o nome do titular')
        return
      }

      if (
        !months &&
        (plan === process.env.REACT_APP_PLAN_ANUAL || plan === process.env.REACT_APP_PLAN_ANUAL_PRO)
      ) {
        setMonthError('é necessário informar o parcelamento')
        return
      }

      if (!validateEmail(email)) {
        setEmailError('é necessário informar um email válido')
        return
      }

      if (!window.Iugu.utils.validateCreditCardNumber(number)) {
        setNumberError('o número do cartão é inválido')
        return
      }

      if (!window.Iugu.utils.validateExpirationString(expiry.replace(/\s/g, ''))) {
        setExpiryError('a data de validade é inválida')
        return
      }

      if (
        !window.Iugu.utils.validateCVV(cvc, window.Iugu.utils.getBrandByCreditCardNumber(number))
      ) {
        setCvcError('o código de segurança é inválido')
        return
      }

      if (!validateCpf(document) && !validateCnpj(document)) {
        setDocumentError('o documento é inválido')
        return
      }

      setIsSending(true)

      const response = await onSubmit({
        name,
        email,
        number,
        expiry,
        cvc,
        months,
        document,
        paymentMethod,
      })

      setIsSending(false)

      if (response.success) {
        setIsSuccess(true)
      }

      if (response.errorMessage) {
        setErrorMessage(response.errorMessage)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, email, number, expiry, cvc, months, document, isSending, isSuccess, paymentMethod],
  )

  useEffect(() => {
    const isFilled = name && email && number && expiry && cvc && document

    setIsFormFilled(isFilled)
  }, [name, email, number, expiry, cvc, document])

  useEffect(() => {
    if (isSuccess) {
      setButtonIcon('mono_check_filled')
      setButtonLabel('pronto')
      setButtonDisabled(false)
    } else if (isSending) {
      setButtonLabel('processando')
      setButtonDisabled(false)
    } else if (isFormFilled) {
      setButtonIcon(null)
      setButtonLabel(labelButton)
      setButtonDisabled(false)
    } else {
      setButtonIcon(null)
      setButtonLabel(labelButton)
      setButtonDisabled(true)
    }
  }, [isFormFilled, isSending, isSuccess, labelButton])

  if (
    plan === process.env.REACT_APP_PLAN_ANUAL ||
    plan === process.env.REACT_APP_PLAN_ANUAL_PRO ||
    plan === process.env.REACT_APP_PLAN_ANUAL_BLACK_FRIDAY
  ) {
    return (
      <div>
        <Logo className={style.logo} />

        <Typography className={style.title} variant="displayM">
          {title}
        </Typography>

        <form className={style.form} onSubmit={handleSubmit}>
          <div className={style.content}>
            <FieldText
              className={style.field}
              name="name"
              value={name}
              readOnly={isSending || isSuccess}
              error={nameError}
              onChange={handleFieldChange}
              placeholder="nome como esta no cartão"
              label="nome do titular"
              autoComplete="cc-name"
            />

            <FieldText
              className={style.field}
              name="email"
              value={email}
              readOnly={isSending || isSuccess}
              error={emailError}
              type="email"
              onChange={handleFieldChange}
              placeholder="digite seu email"
              label="e-mail"
            />

            <div className={style.fieldNumber}>
              <FieldText
                className={style.field}
                name="number"
                value={number}
                readOnly={isSending || isSuccess}
                error={numberError}
                onChange={handleFieldChange}
                placeholder="0123 4567 8910 1112"
                label="número do cartão"
                autoComplete="cc-number"
                inputmode="numeric"
              />
              <span
                className={style.fieldNumberIcon}
                style={{
                  backgroundImage: `url(${creditCardsImages[getBrandByNumber()]})`,
                }}
              />
            </div>

            <div className={style.fieldRow}>
              <FieldText
                className={style.field}
                name="expiry"
                value={expiry}
                readOnly={isSending || isSuccess}
                error={expiryError}
                onChange={handleFieldChange}
                placeholder="MM/AA"
                label="validade"
                autoComplete="cc-exp"
                inputmode="numeric"
              />

              <FieldText
                className={style.field}
                name="cvc"
                value={cvc}
                readOnly={isSending || isSuccess}
                error={cvcError}
                onChange={handleFieldChange}
                placeholder="CVV"
                label="código de segurança"
                autoComplete="cc-csc"
                inputmode="numeric"
              />
            </div>

            <FieldText
              className={style.field}
              name="document"
              value={document}
              readOnly={isSending || isSuccess}
              error={documentError}
              onChange={handleFieldChange}
              placeholder="012.345.678-90"
              label="CPF ou CNPJ"
              inputmode="numeric"
            />

            <FieldSelect
              name="months"
              selectedOption={value}
              onChange={handleChange}
              placeholder="Selecione a quantidade de parcelas"
              label="Parcelar em"
              options={options}
              error={monthError}
              isSearchable
            />
          </div>

          {errorMessage && (
            <CardClean className={style.errorCard} variant="danger">
              <Typography element="p" variant="bodyS">
                {errorMessage}
              </Typography>
            </CardClean>
          )}

          <div className={style.actions}>
            <Button
              variant="primaryGradient"
              type="submit"
              className={style.button}
              disabled={buttonDisabled}
              label={buttonLabel}
              prefixIcon={buttonIcon}
              loading={isSending}
            />
            {pixSlipOnClick && (
              <Button
                variant="tertiary"
                className={style.button}
                label="assinar com Pix"
                onClick={pixSlipOnClick}
              />
            )}
          </div>
        </form>
      </div>
    )
  }

  return (
    <div>
      <Logo className={style.logo} />

      <Typography className={style.title} variant="displayM">
        {title}
      </Typography>

      <form className={style.form} onSubmit={handleSubmit}>
        <div className={style.content}>
          <FieldText
            className={style.field}
            name="name"
            value={name}
            readOnly={isSending || isSuccess}
            error={nameError}
            onChange={handleFieldChange}
            placeholder="nome como esta no cartão"
            label="nome do titular"
            autoComplete="cc-name"
          />

          <FieldText
            className={style.field}
            name="email"
            value={email}
            readOnly={isSending || isSuccess}
            error={emailError}
            type="email"
            onChange={handleFieldChange}
            placeholder="digite seu email"
            label="e-mail"
          />

          <div className={style.fieldNumber}>
            <FieldText
              className={style.field}
              name="number"
              value={number}
              readOnly={isSending || isSuccess}
              error={numberError}
              onChange={handleFieldChange}
              placeholder="0123 4567 8910 1112"
              label="número do cartão"
              autoComplete="cc-number"
              inputmode="numeric"
            />
            <span
              className={style.fieldNumberIcon}
              style={{
                backgroundImage: `url(${creditCardsImages[getBrandByNumber()]})`,
              }}
            />
          </div>

          <div className={style.fieldRow}>
            <FieldText
              className={style.field}
              name="expiry"
              value={expiry}
              readOnly={isSending || isSuccess}
              error={expiryError}
              onChange={handleFieldChange}
              placeholder="MM/AA"
              label="validade"
              autoComplete="cc-exp"
              inputmode="numeric"
            />

            <FieldText
              className={style.field}
              name="cvc"
              value={cvc}
              readOnly={isSending || isSuccess}
              error={cvcError}
              onChange={handleFieldChange}
              placeholder="CVV"
              label="código de segurança"
              autoComplete="cc-csc"
              inputmode="numeric"
            />
          </div>

          <FieldText
            className={style.field}
            name="document"
            value={document}
            readOnly={isSending || isSuccess}
            error={documentError}
            onChange={handleFieldChange}
            placeholder="012.345.678-90"
            label="CPF ou CNPJ"
            inputmode="numeric"
          />
        </div>

        {errorMessage && (
          <CardClean className={style.errorCard} variant="danger">
            <Typography element="p" variant="bodyS">
              {errorMessage}
            </Typography>
          </CardClean>
        )}

        <div className={style.actions}>
          <Button
            variant="primaryGradient"
            type="submit"
            className={style.button}
            disabled={buttonDisabled}
            label={buttonLabel}
            prefixIcon={buttonIcon}
            loading={isSending}
          />
          {pixSlipOnClick && (
            <Button
              variant="tertiary"
              className={style.button}
              label="assinar com Pix"
              onClick={pixSlipOnClick}
            />
          )}
        </div>
      </form>
    </div>
  )
}

FormSubscriptionCreditCard.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  pixSlipOnClick: PropTypes.func,
  title: PropTypes.string,
  labelButton: PropTypes.string,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    plan: PropTypes.string,
  }),
}

FormSubscriptionCreditCard.defaultProps = {
  pixSlipOnClick: null,
  title: 'contratar PRIME com cartão de crédito',
  labelButton: 'assinar com cartão de crédito',
  initialValues: {
    name: '',
  },
}

export default FormSubscriptionCreditCard
