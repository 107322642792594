/* eslint-disable no-console */
import * as intercom from './intercom'
import * as amplitude from './amplitude'
import * as clevertap from './clevertap'

export async function init(callback) {
  await intercom.init()
  await amplitude.init()
  await clevertap.init()

  callback()
}

export async function setUser(user) {
  if (process.env.NODE_ENV !== 'production') {
    console.log('tracker setUser', user)
    return
  }

  await intercom.setUser(user)
  await amplitude.setUser(user)
  await clevertap.setUser(user)
}

export async function clearUser() {
  if (process.env.NODE_ENV !== 'production') {
    console.log('tracker clearUser')
    return
  }

  await intercom.clearUser()
  await amplitude.clearUser()
  await clevertap.clearUser()
}

export async function trackEvent(eventName, eventProps) {
  if (process.env.NODE_ENV !== 'production') {
    console.log('tracker track', eventName, eventProps)
    return
  }

  await intercom.trackEvent(eventName, eventProps)
  await amplitude.trackEvent(eventName, eventProps)
  await clevertap.trackEvent(eventName, eventProps)
}

export async function trackPage(pageName) {
  if (process.env.NODE_ENV !== 'production') {
    console.log('tracker page', pageName)
    return
  }

  await amplitude.trackPage(pageName)
}
