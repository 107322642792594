import React from 'react'
import ReactDOM from 'react-dom'
import Router from 'Router'
import * as serviceWorker from 'serviceWorker'
import { init as firebaseInit } from 'utils/firebase'
import { init as trackerInit } from 'utils/tracker'
import 'css/reset.css'
import '@ahazou/react-components/dist/index.css'
import 'css/tokens.css'

firebaseInit()

trackerInit(() => {
  ReactDOM.render(<Router />, document.getElementById('root'))
})

serviceWorker.unregister()
