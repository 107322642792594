import React from 'react'
import Container from 'components/Container'
import { Icon, Typography } from '@ahazou/react-components'
import style from './style.module.css'

const benefits = [
  '<b>otimize seu tempo</b> com mais de 300 mil posts prontos',
  '<b>transmita profissionalismo</b> com conteúdo sem marca d’água',
  '<b>planeje seu dia,</b> aproveite as datas importantes e <b>venda mais</b>',
]

const SectionSubscriptioBenefits = () => {
  return (
    <div className={style.section}>
      <Container className={style.container}>
        <Typography className={style.title} element="h4" variant="displayL">
          olha o que você ganha com o Ahazou
        </Typography>

        <ul className={style.list}>
          {benefits.map((benefit) => (
            <li key={benefit} className={style.item}>
              <Icon className={style.itemIcon} size="l" icon="mono_check_filled" />
              <Typography className={style.itemText} variant="bodyS" innerHTML={benefit} />
            </li>
          ))}
        </ul>
      </Container>
    </div>
  )
}

export default SectionSubscriptioBenefits
