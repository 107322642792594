import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Logo } from '@ahazou/react-components'
import { MoonLoader } from 'react-spinners'
import { Row, Col } from 'react-bootstrap'
import style from './style.module.css'

const LoadingScreen = ({ loading }) => {
  return (
    <div className={classNames(style.container, { [style.loading]: loading })}>
      <Row>
        <Col>
          <Logo className={style.logo} />
        </Col>
      </Row>

      <Row>
        <Col>
          <MoonLoader color="#000000" />
        </Col>
      </Row>
    </div>
  )
}

LoadingScreen.propTypes = {
  loading: PropTypes.bool.isRequired,
}

export default LoadingScreen
