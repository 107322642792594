import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { WrapComponentWithAppStateConsumer } from 'AppContext'
import { loginWithProvider, recoveryPassword } from 'utils/auth'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import FormLogin from 'components/FormLogin'
import FormRecoveryPassword from 'components/FormRecoveryPassword'

const Login = ({ context }) => {
  const { modalOpen, modalAlertOpen, userSet } = context
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const loginSubmit = async (provider, data) => {
    const responseUser = await loginWithProvider(provider, data)

    if (responseUser.error) {
      return { success: false, error: responseUser.error }
    }

    if (responseUser.status !== 200) {
      modalAlertOpen({
        title: 'algo deu errado!',
        message: `não existe um usuário associado com essa conta em nossa base de usuários <br /><br /> por favor baixe nossa app e efetue um cadastro`,
        actionsButtons: [
          {
            element: 'a',
            label: 'baixar app',
            href: 'https://ahazou.app.goo.gl/web-app',
            target: '_blank',
          },
        ],
        closeLabel: 'fechar',
      })

      return { success: false }
    }

    userSet(responseUser.data)
    setIsAuthenticated(true)

    return { success: true }
  }

  const recoveryPasswordSubmit = useCallback(async (data) => {
    const response = await recoveryPassword(data)

    if (response.error) {
      modalAlertOpen({
        title: 'algo deu errado!',
        message: `não encontramos o email <b>${data.email}</b> em nossa base de usuários <br /><br /> por favor verifique e o email e tente novamente`,
        closeLabel: 'fechar',
      })

      return { success: false, error: response.error }
    }

    modalAlertOpen({
      titleIcon: 'mono_check_filled',
      title: 'tudo certo!',
      message: `enviamos um email para <b>${data.email}</b> com as informações para recuperar a sua senha`,
      closeLabel: 'ok, entendi',
    })

    return { success: true }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const openModalRecoveryPassword = useCallback(() => {
    modalOpen({
      children: <FormRecoveryPassword onSubmit={recoveryPasswordSubmit} />,
    })
  }, [modalOpen, recoveryPasswordSubmit])

  if (isAuthenticated) {
    return <Redirect to="/" />
  }

  return (
    <>
      <Helmet>
        <title>Ahazou - Login</title>
      </Helmet>
      <FormLogin
        onEmailAndPasswordSubmit={(data) => loginSubmit('credentials', data)}
        onGoogleClick={() => loginSubmit('google')}
        onFacebookClick={() => loginSubmit('facebook')}
        onRecoveryPasswordClick={openModalRecoveryPassword}
      />
    </>
  )
}

Login.propTypes = {
  context: PropTypes.shape({
    userSet: PropTypes.func.isRequired,
    modalOpen: PropTypes.func.isRequired,
    modalAlertOpen: PropTypes.func.isRequired,
  }).isRequired,
}

export default WrapComponentWithAppStateConsumer(Login)
