import React from 'react'
import imgBannerPrime from 'images/logo_prime.svg'
import Container from 'components/Container'
import style from './style.module.css'

const BannerSubscription = () => {
  return (
    <div className={style.banner}>
      <Container className={style.container}>
        <figure className={style.figure}>
          <img className={style.image} height="70" src={imgBannerPrime} alt="Ahazou Prime" />
        </figure>
      </Container>
    </div>
  )
}

export default BannerSubscription
