import { initializeApp, firestore, auth } from 'firebase'
import { pickBy, identity } from 'lodash'
import parserUserData from 'utils/parsers/user'
import parserPostData from 'utils/parsers/post'
import parserRelatedPostsData from 'utils/parsers/related-posts'

let firestoreDb

export function init() {
  initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  })

  // Firestore database
  firestoreDb = firestore()

  firestoreDb.settings({
    cacheSizeBytes: firestore.CACHE_SIZE_UNLIMITED,
  })

  firestoreDb.enablePersistence()
}

export async function signInWithEmailAndPassword({ email, password }) {
  return auth()
    .signInWithEmailAndPassword(email, password)
    .then((result) => {
      return result.user
    })
    .catch((error) => {
      console.error(error)
      return { error }
    })
}

export async function signInWithGoogle() {
  const provider = new auth.GoogleAuthProvider()

  return auth()
    .signInWithPopup(provider)
    .then((result) => {
      return result.user
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('auth error: ', error)
      return { error }
    })
}

export async function signInWithFacebook() {
  const provider = new auth.FacebookAuthProvider()

  return auth()
    .signInWithPopup(provider)
    .then((result) => {
      return result.user
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('auth error: ', error)
      return { error }
    })
}

export async function recoveryPassword(email) {
  const recoveryPasswordSettings = {
    url: `${window.location.origin}/login`,
    iOS: {
      bundleId: 'com.carreirabeauty.AhazouPro',
    },
    android: {
      packageName: 'ahzproapp.carreirabeauty.com.ahz_pro',
      installApp: true,
    },
  }

  return auth()
    .sendPasswordResetEmail(email, recoveryPasswordSettings)
    .then(() => {
      return { success: true }
    })
    .catch((error) => {
      return { error }
    })
}

export async function getUser(userId) {
  const snapshot = await firestoreDb.collection('users').doc(userId).get()

  if (snapshot.data()) {
    return parserUserData({
      ...snapshot.data(),
      id: userId,
    })
  }

  return null
}

export function listenUserChange(userId, callback) {
  return firestoreDb
    .collection('users')
    .doc(userId)
    .onSnapshot((doc) => {
      if (doc.data()) {
        callback(
          parserUserData({
            ...doc.data(),
            id: userId,
          }),
        )
      }
    })
}

export async function getDiscoveriesData(segmentsIds, lastDiscovery = {}) {
  const discoveries = []

  const discoveriesPerPage = 5
  const orderField = 'updated_at'

  const querySnapshot = await firestoreDb
    .collection('discoveries')
    .orderBy(orderField, 'desc')
    .where('segments_id', 'array-contains-any', segmentsIds.slice(0, 9))
    .startAfter(lastDiscovery[orderField] || { seconds: null, nanoseconds: null })
    .limit(discoveriesPerPage)
    .get()

  querySnapshot.forEach((doc) => {
    const discoveryData = {
      id: doc.id,
      ...doc.data(),
    }

    const currentDiscoveyIndex = discoveries.findIndex((d) => d.id === doc.id)

    if (currentDiscoveyIndex !== -1) {
      discoveries[currentDiscoveyIndex] = discoveryData
    } else {
      discoveries.push(discoveryData)
    }
  })

  return discoveries
}

export async function getDiscoveriesPostsData(discoveryId) {
  const posts = []

  const postsPerPage = 15

  const querySnapshot = await firestoreDb
    .collection('discoveries')
    .doc(discoveryId)
    .collection('posts')
    .limit(postsPerPage)
    .get()

  querySnapshot.forEach((doc) => {
    const discoveryData = {
      id: doc.id,
      ...doc.data(),
    }

    const currentPostsIndex = posts.findIndex((d) => d.id === doc.id)

    if (currentPostsIndex !== -1) {
      posts[currentPostsIndex] = discoveryData
    } else {
      posts.push(discoveryData)
    }
  })

  return posts
}

export async function getRelationedPostsData(post) {
  const posts = []
  const segments = post.segments ? post.segments.slice(0, 9) : []

  const querySnapshot = await firestoreDb
    .collection('posts')
    .where('segments', 'array-contains-any', segments)
    .limit(10)
    .get()

  querySnapshot.forEach((doc) => {
    const postsData = {
      id: doc.id,
      ...doc.data(),
    }

    const currentPostsIndex = posts.findIndex((d) => d.id === doc.id)

    if (currentPostsIndex !== -1) {
      posts[currentPostsIndex] = postsData
    } else {
      posts.push(postsData)
    }
  })

  const parsedData = parserRelatedPostsData(posts)
  return parsedData
}

export async function getPostData(postId) {
  const querySnapshot = await firestoreDb.collection('posts').doc(postId).get()

  const parsedData = parserPostData(querySnapshot.data())
  return {
    id: postId,
    ...parsedData,
  }
}

export function postCancelReasons(data = {}) {
  const cleanedData = pickBy(data, identity)

  return firestoreDb
    .collection('iugu_cancel_reasons')
    .add(cleanedData)
    .then((docRef) => {
      return { status: 200, data: docRef.id }
    })
    .catch((error) => {
      return { status: 400, error }
    })
}
