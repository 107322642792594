const { Iugu } = window

export default function (data, callback) {
  const {
    user,
    plan,
    name,
    email,
    document,
    addressZipCode,
    addressCity,
    addressUf,
    addressDistrict,
    addressStreet,
    addressNumber,
    addressComplement,
    number,
    expiry,
    cvc,
    paymentMethod,
    months,
  } = data

  const formatedData = {
    name,
    document,
    plan,
  }

  if (user) {
    formatedData.email = email || user.profile.email
    formatedData.userId = user.id
  }

  if (paymentMethod === 'credit_card') {
    const expirySplitted = expiry.replace(/\s/g, '').split('/')
    const firstName = name
      .trim()
      .replace(/\s{2,}/g, ' ')
      .split(' ')
      .slice(0, 1)
      .join(' ')
    const lastName = name
      .trim()
      .replace(/\s{2,}/g, ' ')
      .split(' ')
      .slice(1)
      .join(' ')

    const expiryMonth = expirySplitted[0]
    const expiryYear = `20${expirySplitted[1]}`
    const newNumber = number.replace(/ /g, '')

    const cc = Iugu.CreditCard(newNumber, expiryMonth, expiryYear, firstName, lastName, cvc)

    Iugu.createPaymentToken(cc, (response) => {
      if (response.errors) {
        console.log(response)
        callback(response)
      } else {
        formatedData.payableWith = 'credit_card'
        formatedData.paymentMethod = 'credit_card'
        formatedData.months = months
        formatedData.token = response.id
        callback(formatedData)
      }
    })
  } else if (paymentMethod === 'bank_slip') {
    formatedData.payableWith = 'bank_slip'
    formatedData.paymentMethod = 'bank_slip'
    formatedData.email = email
    formatedData.zipcode = addressZipCode
    formatedData.uf = addressUf
    formatedData.city = addressCity
    formatedData.district = addressDistrict
    formatedData.street = addressStreet
    formatedData.number = addressNumber
    formatedData.complement = addressComplement

    callback(formatedData)
  } else if (paymentMethod === 'pix') {
    formatedData.payableWith = 'pix'
    formatedData.paymentMethod = 'pix'
    formatedData.email = email
    formatedData.zipcode = addressZipCode
    formatedData.uf = addressUf
    formatedData.city = addressCity
    formatedData.district = addressDistrict
    formatedData.street = addressStreet
    formatedData.number = addressNumber
    formatedData.complement = addressComplement

    callback(formatedData)
  }
}
