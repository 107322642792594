import React from 'react'
import PropTypes from 'prop-types'
import { FieldText } from '@ahazou/react-components'
import style from './style.module.css'

const FormProfileManagement = ({ profile }) => {
  return (
    <form className={style.form}>
      <FieldText name="name" value={profile.name} label="nome" readOnly />

      <FieldText name="email" value={profile.contact_email} label="email" readOnly />

      <FieldText name="phone_number" value={profile.phone_number} label="whatsapp" readOnly />
    </form>
  )
}

FormProfileManagement.propTypes = {
  profile: PropTypes.shape({
    name: PropTypes.string,
    contact_email: PropTypes.string,
    phone_number: PropTypes.string,
  }).isRequired,
}

export default FormProfileManagement
