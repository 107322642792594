export function setUser(user) {
  if (window.amplitude) {
    window.amplitude.getInstance().setUserId(user.id)

    window.amplitude.getInstance().setUserProperties({
      userId: user.id,
      email: user.profile.email,
      fullName: user.profile.name,
      name: user.profile.name.split(' ')[0],
      phone: user.profile.phone,
      segments: user.segmentsNames,
      hasLogo: user.logos && user.logos.length > 0,
      ahazouPtz: user.balance,
      ahazouPrime: user.subscription.status === 'active',
    })
  }
}

export function clearUser() {
  if (window.amplitude) {
    window.amplitude.getInstance().setUserId(null)
    window.amplitude.getInstance().regenerateDeviceId()
  }
}

export function trackEvent(eventName, eventProps) {
  if (window.amplitude) {
    window.amplitude.getInstance().logEvent(eventName, eventProps)
  }
}

export function trackPage(pageName) {
  if (window.amplitude) {
    window.amplitude.getInstance().logEvent(pageName)
  }
}

/* eslint-disable */
export function init() {
  return new Promise((resolve) => {
    ;(function (e, t) {
      const n = e.amplitude || {
        _q: [],
        _iq: {},
      }
      const r = t.createElement('script')
      r.type = 'text/javascript'
      r.integrity = 'sha384-3bSR/uIgD42pCWBeq1//B3mI/hPuWdk0L1EUnQIWfGyMOjs0VEoFLhHMqObtv2BA'
      r.crossOrigin = 'anonymous'
      r.async = true
      r.src = 'https://cdn.amplitude.com/libs/amplitude-5.10.0-min.gz.js'
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
          console.log('[Amplitude] Error: could not load SDK')
        }
        resolve()
      }
      const i = t.getElementsByTagName('script')[0]
      i.parentNode.insertBefore(r, i)

      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)))
          return this
        }
      }
      const o = function () {
        this._q = []
        return this
      }
      const a = ['add', 'append', 'clearAll', 'prepend', 'set', 'setOnce', 'unset']
      for (let u = 0; u < a.length; u++) {
        s(o, a[u])
      }
      n.Identify = o
      const c = function () {
        this._q = []
        return this
      }
      const l = ['setProductId', 'setQuantity', 'setPrice', 'setRevenueType', 'setEventProperties']
      for (let p = 0; p < l.length; p++) {
        s(c, l[p])
      }
      n.Revenue = c
      const d = [
        'init',
        'logEvent',
        'logRevenue',
        'setUserId',
        'setUserProperties',
        'setOptOut',
        'setVersionName',
        'setDomain',
        'setDeviceId',
        'enableTracking',
        'setGlobalUserProperties',
        'identify',
        'clearUserProperties',
        'setGroup',
        'logRevenueV2',
        'regenerateDeviceId',
        'groupIdentify',
        'onInit',
        'logEventWithTimestamp',
        'logEventWithGroups',
        'setSessionId',
        'resetSessionId',
      ]

      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)))
          }
        }
        for (let n = 0; n < d.length; n++) {
          t(d[n])
        }
      }
      v(n)
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? '$default_instance' : e).toLowerCase()
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = {
            _q: [],
          }
          v(n._iq[e])
        }
        return n._iq[e]
      }
      e.amplitude = n
    })(window, document)

    window.amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY)
  })
}
