import React from 'react'
import PropTypes from 'prop-types'
import { Button, Typography } from '@ahazou/react-components'
import imgCancelIllustration from 'images/cancel-illustration.svg'
import style from './style.module.css'

const AlertSubscriptionCancelSuccess = ({ name, onConfirm }) => {
  return (
    <div className={style.container}>
      <Typography className={style.title} variant="displayM">
        {name && `${name}, `}sua assinatura Ahazou PRIME foi cancelada
      </Typography>

      <figure className={style.figure}>
        <img
          className={style.image}
          src={imgCancelIllustration}
          alt="Ilustração de pessoa triste chorando ao ser removido uma coroa de sua cabeça"
        />
      </figure>

      <div className={style.content}>
        <Typography className={style.description} variant="bodyM">
          ficamos tristes com sua partida, mas sempre que quiser turbinar suas redes sociais,
          teremos posts incríveis e ilimitados esperando por você.
        </Typography>

        <Typography className={style.description} variant="bodyM">
          por enquanto, você ainda pode aproveitar os benefícios do Ahazou PRIME até o fim do
          período vigente.
        </Typography>
      </div>

      <div className={style.actions}>
        <Button
          variant="secondary"
          type="button"
          className={style.button}
          label="voltar para o app Ahazou"
          onClick={onConfirm}
        />
      </div>
    </div>
  )
}

AlertSubscriptionCancelSuccess.propTypes = {
  name: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
}

AlertSubscriptionCancelSuccess.defaultProps = {
  name: null,
}

export default AlertSubscriptionCancelSuccess
