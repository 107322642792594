import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import style from './style.module.css'

const MyAccountContent = ({ children }) => {
  return (
    <div
      className={classNames(style.wrapper, {
        [style.mobile]: isMobile,
        [style.desktop]: !isMobile,
      })}
    >
      {children}
    </div>
  )
}

MyAccountContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default MyAccountContent
