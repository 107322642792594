import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { NavLink } from 'react-router-dom'
import { Button, Typography, Icon } from '@ahazou/react-components'
import formatCurrency from 'utils/format-currency'
import style from './style.module.css'

const MyProfileNav = ({
  userName,
  // segmentsNames,
  isPrime,
  price,
}) => {
  return (
    <div
      className={classNames(style.wrapper, {
        [style.mobile]: isMobile,
        [style.desktop]: !isMobile,
      })}
    >
      {isMobile && (
        <Typography className={style.title} element="h1" variant="displayL">
          minha conta
        </Typography>
      )}

      <nav className={style.nav}>
        <ul className={style.list}>
          <li className={style.item}>
            <NavLink
              to="/minha-conta/meus-dados"
              activeClassName={style.activeAnchor}
              className={style.anchor}
            >
              <Icon className={style.icon} icon="color_profile" size="l" />
              <p className={style.label}>
                <Typography className={style.labelTitle} element="span" variant="buttonM">
                  {userName}
                </Typography>
                <Typography className={style.labelDescription} element="span" variant="buttonS">
                  meus dados
                </Typography>
              </p>
            </NavLink>
          </li>

          <li className={style.item}>
            <NavLink
              to="/minha-conta/assinatura"
              activeClassName={style.activeAnchor}
              className={style.anchor}
            >
              <Icon className={style.icon} icon="color_prime" size="l" />
              <p className={style.label}>
                <Typography className={style.labelTitle} element="span" variant="buttonM">
                  {isPrime ? 'assinatura PRIME' : 'seja Ahazou PRIME'}
                </Typography>
                <Typography className={style.labelDescription} element="span" variant="buttonS">
                  {isPrime ? 'ativa' : `só R$ ${formatCurrency(price)} / mês`}
                </Typography>
              </p>

              {!isPrime && (
                <Button element="span" display="inline" variant="tertiary" label="assine" />
              )}
            </NavLink>
          </li>

          {/* <li className={style.item}>
            <NavLink
              to="/minha-conta/temas"
              activeClassName={style.activeAnchor}
              className={style.anchor}
            >
              <Icon className={style.icon} icon="color_category" size="l" />
              <p className={style.label}>
                <Typography className={style.labelTitle} element="span" variant="buttonM">
                  temas de publicações
                </Typography>
                <Typography className={style.labelDescription} element="span" variant="buttonS">
                  {segmentsNames.join(', ').substr(0, 25)}...
                </Typography>
              </p>
            </NavLink>
          </li> */}

          <li className={style.item}>
            <button
              type="button"
              className={style.anchor}
              onClick={() => {
                window.Intercom('show')
              }}
            >
              <Icon className={style.icon} icon="color_help" size="l" />
              <p className={style.label}>
                <Typography className={style.labelTitle} element="span" variant="buttonM">
                  ajuda
                </Typography>
              </p>
            </button>
          </li>

          <li className={style.item}>
            <NavLink to="/logout" activeClassName={style.activeAnchor} className={style.anchor}>
              <Icon className={style.icon} icon="color_exit" size="l" />
              <p className={style.label}>
                <Typography className={style.labelTitle} element="span" variant="buttonM">
                  sair
                </Typography>
              </p>
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  )
}

MyProfileNav.propTypes = {
  userName: PropTypes.string.isRequired,
  // segmentsNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  isPrime: PropTypes.bool.isRequired,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

export default MyProfileNav
