import request from './request'

export function getPosts() {
  return request({
    baseURL: process.env.REACT_APP_API_WEB_URL,
    method: 'GET',
    url: '/5e42bb702f00004a0087f8f9',
  })
}

export function postLoginFromToken(firebaseToken) {
  return request({
    baseURL: process.env.REACT_APP_API_WEB_URL,
    method: 'POST',
    url: '/users/login',
    data: {
      token: firebaseToken,
    },
  })
}
