import stringIsJson from 'utils/string-is-json'

export const setLocalStorageItem = (key, value) => {
  let val = value

  if (typeof val !== 'string') {
    val = JSON.stringify(val)
  }

  window.localStorage.setItem(`${process.env.REACT_APP_SLUG}:${key}`, val)
}

export const getLocalStorageItem = (key) => {
  const value = window.localStorage.getItem(`${process.env.REACT_APP_SLUG}:${key}`)
  return stringIsJson(value) ? JSON.parse(value) : value
}

export const removeLocalStorageItem = (key) => {
  window.localStorage.removeItem(`${process.env.REACT_APP_SLUG}:${key}`)
}

export const clearLocalStorage = () => {
  window.localStorage.clear()
}
