import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { validateEmail } from 'utils/validators'
import { Button, Card, FieldText, Logo } from '@ahazou/react-components'
import style from './style.module.css'

const FormLogin = ({
  onGoogleClick,
  onFacebookClick,
  onEmailAndPasswordSubmit,
  onRecoveryPasswordClick,
}) => {
  const [loginWithEmail, setLoginWithEmail] = useState(false)
  const [isFormFilled, setIsFormFilled] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const [buttonLabel, setButtonLabel] = useState('')
  const [buttonIcon, setButtonIcon] = useState(null)
  const [buttonDisabled, setButtonDisabled] = useState(true)

  const fieldsMap = {
    email: { setValue: setEmail, setError: setEmailError },
    password: { setValue: setPassword, setError: setPasswordError },
  }

  const handleFieldChange = (e) => {
    const { name, value } = e.target

    fieldsMap[name].setValue(value)
    fieldsMap[name].setError('')
  }

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()

      if (isSending || isSuccess) {
        return
      }

      if (!email) {
        setEmailError('é necessário informar o email')
        return
      }

      if (!validateEmail(email)) {
        setEmailError('é necessário informar um email válido')
        return
      }

      if (!password) {
        setPasswordError('é necessário informar a senha')
        return
      }

      setIsSending(true)

      const response = await onEmailAndPasswordSubmit({
        email,
        password,
      })
      setIsSending(false)

      if (response.success) {
        setIsSuccess(true)
      } else {
        setIsSuccess(false)
        setPasswordError(response.error && response.error.message)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [email, password, isSending, isSuccess],
  )

  useEffect(() => {
    const isFilled = email && password
    setIsFormFilled(isFilled)
  }, [email, password])

  useEffect(() => {
    if (isSuccess) {
      setButtonIcon('mono_check_filled')
      setButtonLabel('pronto')
      setButtonDisabled(false)
    } else if (isSending) {
      setButtonLabel('entrando')
      setButtonDisabled(false)
    } else if (isFormFilled) {
      setButtonIcon(null)
      setButtonLabel('entrar')
      setButtonDisabled(false)
    } else {
      setButtonIcon(null)
      setButtonLabel('entrar')
      setButtonDisabled(true)
    }
  }, [isFormFilled, isSending, isSuccess])

  return (
    <Card className={style.card}>
      {loginWithEmail ? (
        <Button
          className={style.backButton}
          display="circularMicro"
          variant="tertiary"
          prefixIcon="mono_arrow_left"
          onClick={() => setLoginWithEmail(false)}
        />
      ) : null}

      <Logo className={style.logo} />

      {loginWithEmail ? (
        <form className={style.form} onSubmit={handleSubmit}>
          <FieldText
            className={style.field}
            name="email"
            type="email"
            value={email}
            readOnly={isSending || isSuccess}
            error={emailError}
            onChange={handleFieldChange}
            label="email"
          />

          <FieldText
            className={style.field}
            name="password"
            type="password"
            value={password}
            readOnly={isSending || isSuccess}
            error={passwordError}
            onChange={handleFieldChange}
            label="senha"
          />

          <Button
            variant="primaryGradient"
            type="submit"
            className={style.button}
            disabled={buttonDisabled}
            label={buttonLabel}
            prefixIcon={buttonIcon}
            loading={isSending}
          />

          {onRecoveryPasswordClick && (
            <Button
              type="button"
              display="inline"
              variant="tertiary"
              className={style.button}
              label="esqueci a senha"
              onClick={onRecoveryPasswordClick}
            />
          )}
        </form>
      ) : (
        <div className={style.form}>
          <Button
            className={style.providerButton}
            disabled={isSending}
            loading={isSending}
            variant="secondary"
            type="button"
            label="entrar com Google"
            prefixIcon="graph_google"
            onClick={onGoogleClick}
          />

          <Button
            className={style.providerButton}
            disabled={isSending}
            loading={isSending}
            variant="secondary"
            type="button"
            label="entrar com Facebook"
            prefixIcon="graph_facebook"
            onClick={onFacebookClick}
          />

          <Button
            variant="secondary"
            type="button"
            label="entrar com Email"
            prefixIcon="mono_email_a"
            className={style.providerButton}
            disabled={isSending}
            loading={isSending}
            onClick={() => setLoginWithEmail(true)}
          />
        </div>
      )}
    </Card>
  )
}

FormLogin.propTypes = {
  onGoogleClick: PropTypes.func.isRequired,
  onFacebookClick: PropTypes.func.isRequired,
  onEmailAndPasswordSubmit: PropTypes.func.isRequired,
  onRecoveryPasswordClick: PropTypes.func,
}

FormLogin.defaultProps = {
  onRecoveryPasswordClick: null,
}

export default FormLogin
