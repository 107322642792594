import { keys, values, flatten } from 'lodash'

export default function (data) {
  return {
    ...data,
    profile: {
      name: '',
      email: '',
      contact_email: '',
      ...data.profile,
    },
    subscription: {
      status: 'inactive',
      invoice: {},
      ...data.subscription,
    },
    segments: {
      macro: data.segments ? keys(data.segments) : [],
      micro: data.segments ? flatten(values(data.segments)) : [],
    },
  }
}
