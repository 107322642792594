import React from 'react'
import PropTypes from 'prop-types'
import { Button, Typography, Icon } from '@ahazou/react-components'
import style from './style.module.css'

const benefits = [
  {
    text: '<b>posts incríveis</b> que deixam seu negócio muito mais profissional nas redes sociais',
    icon: 'mono_post_collection',
  },
  {
    text:
      '<b>frequência diária de publicação</b>, levando os algoritmos das redes sociais a priorizarem seu post',
    icon: 'mono_recurrency',
  },
  {
    text: 'conteúdo de impacto para atrair <b>novos clientes</b>',
    icon: 'mono_like',
  },
  {
    text: '<b>mais de 10h economizadas</b> por semana na produção de conteúdo digital',
    icon: 'mono_wait',
  },
  {
    text: '<b>sugestões de posts</b> especiais que desempenham melhor para seu tipo de negócio',
    icon: 'mono_idea',
  },
  {
    text: '<b>valor promocional</b> por tempo limitado',
    icon: 'mono_promo',
  },
]

const AlertSubscriptionCancel = ({ onConfirm, onCancel }) => {
  return (
    <div className={style.container}>
      <Typography className={style.title} variant="displayM">
        quer mesmo cancelar sua assinatura Ahazou PRIME?
      </Typography>

      <div className={style.content}>
        <Typography className={style.description} variant="bodyM">
          o que você está deixando para trás:
        </Typography>

        <ul className={style.list}>
          {benefits.map((benefit) => (
            <li key={benefit.text} className={style.item}>
              <Icon className={style.icon} icon={benefit.icon} size="m" />
              <Typography className={style.text} variant="bodyS" innerHTML={benefit.text} />
            </li>
          ))}
        </ul>
      </div>

      <div className={style.actions}>
        <Button
          variant="primary"
          type="button"
          className={style.button}
          label="continuar com meu Ahazou PRIME"
          onClick={onCancel}
        />

        <Button
          variant="secondary"
          type="button"
          className={style.button}
          label="cancelar meu Ahazou PRIME"
          onClick={onConfirm}
        />
      </div>
    </div>
  )
}

AlertSubscriptionCancel.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default AlertSubscriptionCancel
