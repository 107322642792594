import React from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { AppStateProvider } from 'AppContext'
import Login from 'pages/Login'
import LoginFromUrl from 'pages/LoginFromUrl'
// import Home from 'pages/Home'
// import SegmentDetail from 'pages/SegmentDetail'
// import PostDetail from 'pages/PostDetail'
import Subscription from 'pages/Subscription'
import MyAccount from 'pages/MyAccount'
import Logout from 'pages/Logout'
import App from 'components/App'
import { getLocalStorageItem } from 'utils/storage-handler'

const isUserAllowed = (reverseCondition) => {
  const user = getLocalStorageItem('user') || {}
  let isAllowed = Boolean(user.id)

  if (reverseCondition) {
    isAllowed = !isAllowed
  }

  return isAllowed
}

const PrivateRoute = ({ component: Component, reverseCondition, redirectTo, ...rest }) => {
  const isAllowed = isUserAllowed(reverseCondition)

  return (
    <Route
      {...rest}
      render={(props) => (isAllowed ? <Component {...props} /> : <Redirect to={redirectTo} />)}
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  reverseCondition: PropTypes.bool,
  redirectTo: PropTypes.string,
}

PrivateRoute.defaultProps = {
  reverseCondition: false,
  redirectTo: `/login`,
}

export default function Routes() {
  return (
    <AppStateProvider>
      <Router>
        <App>
          <Switch>
            {/* Only authenticated */}

            <PrivateRoute exact path="/" component={Subscription} />
            <PrivateRoute exact path="/posts/:postId" component={Subscription} />
            <PrivateRoute exact path="/segments/:segmentId" component={Subscription} />
            {/* <PrivateRoute exact path="/" component={Subscription} /> */}

            <PrivateRoute exact path="/assinar" component={Subscription} />
            <PrivateRoute exact path="/assinar/:planIdentifier" component={Subscription} />
            <PrivateRoute
              exact
              path="/assinar/:planIdentifier/:payableWith"
              component={Subscription}
            />
            <PrivateRoute path="/minha-conta" component={MyAccount} />

            {/* Only unauthenticated */}
            <PrivateRoute reverseCondition redirectTo="/" exact path="/login" component={Login} />

            {/* Public for everyone unauthenticated */}
            <Route exact path="/login/:authToken" component={LoginFromUrl} />
            <Route exact path="/login/:authToken/:webApp" component={LoginFromUrl} />
            <Route exact path="/logout" component={Logout} />

            {/* Default route (404) */}
            <PrivateRoute component={Subscription} />
          </Switch>
        </App>
      </Router>
    </AppStateProvider>
  )
}
