import React from 'react'
import PropTypes from 'prop-types'
import Container from 'components/Container'
import { Button, Typography } from '@ahazou/react-components'
import imgTestimonials from 'images/prime-testimonials.svg'
import style from './style.module.css'

const SectionSubscriptionTestimonials = ({ onSubscriptionClick, isWebApp }) => {
  let labelBuyButton

  if (isWebApp) {
    labelBuyButton = 'assinar'
  } else {
    labelBuyButton = 'assinar PRIME'
  }

  return (
    <div className={style.section}>
      <Container className={style.container}>
        <Typography className={style.title} element="h4" variant="displayL">
          o que dizem os clientes
        </Typography>

        <div className={style.testimonialsContainer}>
          <figure className={style.figure}>
            <img
              className={style.image}
              src={imgTestimonials}
              alt="Depoimentos de clientes do Ahazou"
            />
          </figure>
        </div>

        <Button className={style.button} onClick={onSubscriptionClick} label={labelBuyButton} />
      </Container>
    </div>
  )
}

SectionSubscriptionTestimonials.propTypes = {
  onSubscriptionClick: PropTypes.func.isRequired,
  isWebApp: PropTypes.bool,
}

SectionSubscriptionTestimonials.defaultProps = {
  isWebApp: false,
}

export default SectionSubscriptionTestimonials
