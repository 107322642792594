import React, { useEffect, useState } from 'react'
import { useParams, useLocation, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { WrapComponentWithAppStateConsumer } from 'AppContext'
import { authUserWithTokenOrUID, logout } from 'utils/auth'
import { getLocalStorageItem, setLocalStorageItem } from 'utils/storage-handler'

const LoginFromUrl = ({ context }) => {
  const { userSet, userClear, appLoadingSet } = context
  const { authToken, webApp } = useParams()
  const [redirectUrl, setRedirectUrl] = useState('/login')
  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation()
  const { redirectTo } = queryString.parse(location.search)

  useEffect(() => {
    const loginUser = async () => {
      const currentUser = getLocalStorageItem('user') || {}
      const hasUserSession = Boolean(currentUser.id)

      appLoadingSet(true)

      if (hasUserSession) {
        logout()
        userClear()
      }

      if (webApp === 'webapp') {
        setLocalStorageItem('webApp', true)
      } else {
        setLocalStorageItem('webApp', false)
      }

      const responseUserFromToken = await authUserWithTokenOrUID(authToken)

      if (responseUserFromToken.status === 200) {
        appLoadingSet(false)

        userSet(responseUserFromToken.data)
        setRedirectUrl(redirectTo || '/')
      }

      setIsLoading(false)
    }

    loginUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return null
  }

  return <Redirect to={redirectUrl} />
}

LoginFromUrl.propTypes = {
  context: PropTypes.shape().isRequired,
}

export default WrapComponentWithAppStateConsumer(LoginFromUrl)
