import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { NavHeader as AhzNavHeader, NavItem as AhzNavItem } from '@ahazou/react-components'
import { Link, useLocation } from 'react-router-dom'
import style from './style.module.css'

const HomeLink = ({ children, className, url }) => (
  <Link to={url} className={className}>
    {children}
  </Link>
)

HomeLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  url: PropTypes.string,
}

HomeLink.defaultProps = {
  className: null,
  url: '/',
}

const NavHeader = () => {
  const [isTransparent, setIsTransparent] = useState(false)
  const [navOpened, setNavOpened] = useState(false)
  const location = useLocation()
  const { pathname } = location

  const closeNav = () => {
    setNavOpened(false)
  }

  const handleScroll = useCallback(() => {
    const pagesTransparent = new RegExp('^/assinatura?')

    if (!pagesTransparent.test(pathname)) {
      setIsTransparent(false)
      return
    }

    const scrollYLimit = 30

    if (window.scrollY >= scrollYLimit && isTransparent) {
      setIsTransparent(false)
    } else if (window.scrollY < scrollYLimit && !isTransparent) {
      setIsTransparent(true)
    }
  }, [isTransparent, pathname])

  useEffect(() => {
    /**
     * Call `handleScroll` to initialize `isTransparent`
     * correctly if user are in middle of page and reload the page.
     */
    handleScroll()

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll, isTransparent, pathname])

  return (
    <div className={style.header}>
      <AhzNavHeader
        fixed
        logoContainer={HomeLink}
        transparent={isTransparent}
        navOpened={navOpened}
        onNavClick={() => {
          setNavOpened(!navOpened)
        }}
      >
        {/* <AhzNavItem
          element={Link}
          transparent={isTransparent}
          to="/"
          label="início"
          icon="mono_home"
          onClick={closeNav}
        /> */}

        <AhzNavItem
          element={Link}
          transparent={isTransparent}
          to="/minha-conta"
          label="perfil"
          icon="mono_profile"
          onClick={closeNav}
        />
      </AhzNavHeader>
    </div>
  )
}

export default NavHeader
