import { postLoginFromToken } from 'utils/api/web'
import { syncUser } from 'utils/api/subscription'
import { setLocalStorageItem, removeLocalStorageItem } from 'utils/storage-handler'
import {
  signInWithEmailAndPassword,
  signInWithGoogle,
  signInWithFacebook,
  getUser,
  recoveryPassword as firebaseRecoveryPassword,
} from 'utils/firebase'

/**
 * Auth user on web-ahazou and fetch data from firebase firestore
 *
 * @param {String} authToken can be firebase uuid or userId
 * @returns {Object} userResponse
 */
export async function authUserWithTokenOrUID(authToken) {
  // get userId from server by firebase_auth_token
  const responseLoginFromToken = await postLoginFromToken(authToken)
  let userId = authToken

  // if ok, replace userId
  if (responseLoginFromToken.status === 200) {
    userId = responseLoginFromToken.user_id
  }

  // fetch data from firestore
  const user = await getUser(userId)

  if (user) {
    await syncUser(userId)
    setLocalStorageItem('user', { id: userId })
    return { status: 200, data: user }
  }

  return { status: 403, error: { message: 'Invalid auth token' } }
}

export async function loginWithProvider(provider, credentialsData) {
  let response

  if (provider === 'google') {
    response = await signInWithGoogle()
  } else if (provider === 'facebook') {
    response = await signInWithFacebook()
  } else if (provider === 'credentials') {
    response = await signInWithEmailAndPassword(credentialsData)
  }

  if (response.error) {
    const responseMap = {
      'auth/user-not-found': {
        message: 'email ou senha incorretos',
      },
      'auth/wrong-password': {
        message: 'email ou senha incorretos',
      },
    }

    return {
      status: response.error.code,
      error: {
        ...response.error,
        ...responseMap[response.error.code],
      },
    }
  }

  return authUserWithTokenOrUID(response.uid)
}

export async function logout() {
  removeLocalStorageItem('user')
  // TODO: call firebase auth logout method
}

export async function recoveryPassword({ email }) {
  return firebaseRecoveryPassword(email)
}
