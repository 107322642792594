import React, { useEffect, useCallback, useState } from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { WrapComponentWithAppStateConsumer } from 'AppContext'
import { logout } from 'utils/auth'

const Logout = ({ context }) => {
  const { userClear } = context
  const [isLoading, setIsLoading] = useState(true)

  const logoutUser = useCallback(async () => {
    logout()
    userClear()

    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    logoutUser()
  }, [logoutUser])

  if (isLoading) {
    return null
  }

  return <Redirect to="/login" />
}

Logout.propTypes = {
  context: PropTypes.shape({
    userClear: PropTypes.func.isRequired,
  }).isRequired,
}

export default WrapComponentWithAppStateConsumer(Logout)
