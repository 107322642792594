import React from 'react'
import PropTypes from 'prop-types'
import { WrapComponentWithAppStateConsumer } from 'AppContext'
import { Helmet } from 'react-helmet'
import { Typography } from '@ahazou/react-components'
import FormProfileManagement from 'components/FormProfileManagement'

const MyAccountData = ({ context }) => {
  const { state } = context
  const { user } = state

  return (
    <>
      <Helmet>
        <title>Ahazou - Meus Dados</title>
      </Helmet>
      <Typography element="h2" variant="displayL">
        Ahazou PRIME
      </Typography>

      <FormProfileManagement {...user} />
    </>
  )
}

MyAccountData.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.shape({
      user: PropTypes.shape({
        profile: PropTypes.shape({
          name: PropTypes.string,
          email: PropTypes.string,
        }),
        segments: PropTypes.shape({
          macro: PropTypes.arrayOf(PropTypes.string),
          micro: PropTypes.arrayOf(PropTypes.string),
        }),
        subscription: PropTypes.shape({
          invoice: PropTypes.shape({
            type: PropTypes.oneOf(['credit_card', 'bank_slip']),
          }),
        }),
      }),
    }),
  }).isRequired,
}

export default WrapComponentWithAppStateConsumer(MyAccountData)
