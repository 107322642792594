import React from 'react'
import PropTypes from 'prop-types'
import { WrapComponentWithAppStateConsumer } from 'AppContext'
import { Modal as AhzModal } from '@ahazou/react-components'
import ModalAlert from 'components/ModalAlert'

const Modal = ({ context }) => {
  const { state, modalClose } = context
  const { modalProps } = state
  const { children, alert, blocked, className } = modalProps

  if (!state.modalProps.children && (!alert || !alert.message)) {
    return null
  }

  return (
    <AhzModal
      opened={state.modalOpened}
      onClose={modalClose}
      blocked={blocked}
      className={className}
    >
      {children || (
        <ModalAlert
          titleIcon={alert.titleIcon}
          title={alert.title}
          message={alert.message}
          closeLabel={alert.closeLabel}
          actionsButtons={alert.actionsButtons}
          onClose={() => modalClose()}
        />
      )}
    </AhzModal>
  )
}

Modal.propTypes = {
  context: PropTypes.shape({
    modalClose: PropTypes.func,
    state: PropTypes.shape({
      modalOpened: PropTypes.bool,
      modalProps: PropTypes.shape({
        blocked: PropTypes.bool,
        onClose: PropTypes.func,
        className: PropTypes.string,

        children: PropTypes.node,

        alert: PropTypes.shape({
          titleIcon: PropTypes.string,
          title: PropTypes.string.isRequired,
          message: PropTypes.string.isRequired,
          closeLabel: PropTypes.string,
          actionsButtons: PropTypes.arrayOf(PropTypes.shape()),
        }),
      }),
    }),
  }).isRequired,
}

export default WrapComponentWithAppStateConsumer(Modal)
