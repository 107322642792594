/* eslint-disable camelcase */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import DateTime from 'luxon/src/datetime'
import * as clipboard from 'clipboard-polyfill'
import { isMobile } from 'react-device-detect'
import { Button, Typography, CardClean, Icon } from '@ahazou/react-components'
import CardBenefits from 'components/CardBenefits'
import style from './style.module.css'

const MyAccountSubscriptionManagement = ({
  expires_at,
  provider,
  status,
  invoice,
  payment_method,
  onChangePaymentClick,
  onCancelClick,
  onChangeCreditCardClick,
}) => {
  const isInvoicePending = invoice?.status === 'pending'
  const [isBarCodeCopied, setIsBarCodeCopied] = useState(false)

  const { type: paymentMethodType, display_number: creditCardNumber } = payment_method != null

  const dueDate = invoice?.due_date ? invoice.due_date : DateTime.now
  const formatedNextInvoice =
    !isInvoicePending && expires_at ?
      DateTime.fromISO(expires_at).toFormat('dd/LL/yyyy') :
      DateTime.fromISO(dueDate).toFormat('dd/LL/yyyy')

  const isBankSlip = !(
    invoice?.payable_with === 'credit_card' && paymentMethodType === 'credit_card'
  )

  const providersMap = {
    google_pay: 'Google Pay',
    apple_pay: 'Apple Pay',
  }
  const providersUrlMap = {
    google_pay: 'https://play.google.com/store/account/subscriptions',
    apple_pay: 'https://support.apple.com/pt-br/HT202039',
  }

  const copyBarCode = () => {
    clipboard.writeText(invoice.barcode)
    setIsBarCodeCopied(true)

    window.setTimeout(() => {
      setIsBarCodeCopied(false)
    }, 3000)
  }

  if (provider !== 'iugu' && providersMap[provider]) {
    return (
      <>
        <Typography>Assinatura feita através do {providersMap[provider]}.</Typography>
        <Button
          label="gerenciar assinatura"
          element="a"
          href={providersUrlMap[provider]}
          target="_blank"
        />
      </>
    )
  }

  if (provider !== 'iugu') {
    return <Typography>Para gerenciar sua assinatura, acesse nosso aplicativo.</Typography>
  }

  return (
    <div
      className={classNames(style.wrapper, {
        [style.mobile]: isMobile,
        [style.desktop]: !isMobile,
      })}
    >
      <div className={style.benefits}>
        <Typography className={style.benefitsDescription} variant="bodyS">
          como assinante PRIME, você tem acesso a benefícios exclusivos:
        </Typography>

        <CardBenefits />
      </div>

      <div className={style.info}>
        <ul className={style.infoList}>
          <li className={style.infoItem}>
            <Icon className={style.infoIcon} icon="color_prime" size="m" />
            <p className={style.infoText}>
              <Typography className={style.infoTitle} variant="buttonM">
                assinatura PRIME
              </Typography>

              <Typography className={style.infoDescription} variant="buttonS">
                {/* pending', 'paid', 'canceled', 'refunded', 'expired */}
                {status === 'active' && 'ativa'}
                {status === 'inactive' && 'inativa'}
              </Typography>
            </p>
          </li>
          <li className={style.infoItem}>
            <Icon
              className={style.infoIcon}
              icon={isBankSlip ? 'color_barcode' : 'graph_card_mastercard'}
              size="m"
            />

            <p className={style.infoText}>
              <Typography className={style.infoTitle} variant="buttonM">
                forma de pagamento
              </Typography>

              <Typography className={style.infoDescription} variant="buttonS">
                {/* eslint-disable-next-line no-nested-ternary */}
                {invoice?.payable_with === 'credit_card' ?
                  'cartão de crédito' :
                  invoice?.payable_with === 'bank_slip' ?
                  'boleto' :
                  'pix'}

                {!isBankSlip &&
                  creditCardNumber &&
                  `- final ${creditCardNumber.replace(/[^0-9]/g, '')}`}
              </Typography>
            </p>

            {!isBankSlip && (
              <Button
                label="alterar cartão"
                display="inline"
                variant="tertiary"
                bordered
                onClick={onChangeCreditCardClick}
              />
            )}
          </li>

          {isInvoicePending && invoice.url ? (
            <li className={style.infoItem}>
              <Icon className={style.infoIcon} icon="mono_calendar_idle" size="m" />
              <p className={style.infoText}>
                <Typography className={style.infoTitle} variant="buttonM">
                  {invoice?.payable_with === 'credit_card' || invoice?.payable_with === 'pix' ?
                    'fatura disponível' :
                    'boleto já disponível'}
                </Typography>

                <Typography
                  className={classNames(style.infoDescription, style.infoDescriptionHighlight)}
                  variant="buttonS"
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {invoice?.payable_with === 'credit_card' || invoice?.payable_with === 'pix' ?
                    'pague a fatura para ' :
                    'pague o boleto para '}
                  {status === 'active' ? 'manter seu PRIME ativo' : 'ativar seu PRIME'}
                </Typography>
              </p>
            </li>
          ) : (
            <li className={style.infoItem}>
              <Icon className={style.infoIcon} icon="mono_calendar_idle" size="m" />
              <p className={style.infoText}>
                <Typography className={style.infoTitle} variant="buttonM">
                  Próxima cobrança
                </Typography>

                <Typography className={style.infoDescription} variant="buttonS">
                  {formatedNextInvoice}
                </Typography>
              </p>
            </li>
          )}
        </ul>
        {isInvoicePending && invoice.barcode && (
          <div className={style.infoBill}>
            <CardClean className={style.infoBillCard}>
              <Typography className={style.infoBillText} variant="bodyS">
                {invoice.barcode}
              </Typography>
              <Button
                className={style.infoBillClipboardButton}
                variant="tertiary"
                display="inline"
                label={!isBarCodeCopied ? 'copiar código de barras' : 'código copiado!'}
                onClick={copyBarCode}
              />
            </CardClean>
          </div>
        )}
      </div>

      <div className={style.actions}>
        {isInvoicePending && invoice.url && (
          <Button
            className={style.actionsButton}
            element="a"
            href={invoice.url}
            target="_blank"
            label={
              invoice?.payable_with === 'credit_card' || invoice?.payable_with === 'pix' ?
                `ver fatura` :
                'ver boleto'
            }
          />
        )}

        <Button
          className={style.actionsButton}
          variant="secondary"
          label="mudar forma de pagamento"
          onClick={onChangePaymentClick}
        />

        <Button
          className={style.actionsButton}
          variant="secondary"
          label="cancelar assinatura"
          onClick={onCancelClick}
        />
      </div>
    </div>
  )
}

MyAccountSubscriptionManagement.propTypes = {
  onChangePaymentClick: PropTypes.func.isRequired,
  onChangeCreditCardClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  status: PropTypes.oneOf(['active', 'inactive']).isRequired,
  provider: PropTypes.oneOf(['iugu', 'google_pay', 'apple_pay']).isRequired,
  expires_at: PropTypes.string,
  invoice: PropTypes.shape({
    due_date: PropTypes.string,
    status: PropTypes.oneOf(['pending', 'paid', 'canceled', 'refunded', 'expired']).isRequired,
    payable_with: PropTypes.oneOf(['credit_card', 'bank_slip', 'pix']),
    barcode: PropTypes.string,
    url: PropTypes.string,
  }),
  payment_method: PropTypes.shape({
    type: PropTypes.oneOf(['credit_card', 'bank_slip', 'pix']),
    brand: PropTypes.string,
    display_number: PropTypes.string,
  }),
}

MyAccountSubscriptionManagement.defaultProps = {
  expires_at: null,
  invoice: {},
  payment_method: {},
}

export default MyAccountSubscriptionManagement
