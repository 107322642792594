import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import msk from 'msk'
import cep from 'cep-promise'
import { validateEmail, validateCpf, validateCnpj, validateCEP } from 'utils/validators'
import {
  Button,
  CardClean,
  FieldSelect,
  FieldText,
  Typography,
  Logo,
} from '@ahazou/react-components'
import citiesAndStates from './cities-and-states.json'
import style from './style.module.css'

const FormSubscriptionBankSlip = ({
  onSubmit,
  creditCardOnClick,
  title,
  labelButton,
  initialValues,
}) => {
  const [isFormFilled, setIsFormFilled] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [name, setName] = useState(initialValues.name)
  const [email, setEmail] = useState(initialValues.email)
  const [document, setDocument] = useState('')
  const [addressZipCode, setAddressZipCode] = useState('')
  const [addressCity, setAddressCity] = useState('')
  const [addressUf, setAddressUf] = useState('')
  const [addressDistrict, setAddressDistrict] = useState('')
  const [addressStreet, setAddressStreet] = useState('')
  const [addressNumber, setAddressNumber] = useState('')
  const [addressComplement, setAddressComplement] = useState('')

  const [nameError, setNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [documentError, setDocumentError] = useState('')
  const [addressZipCodeError, setAddressZipCodeError] = useState('')
  const [addressCityError, setAddressCityError] = useState('')
  const [addressUfError, setAddressUfError] = useState('')
  const [addressDistrictError, setAddressDistrictError] = useState('')
  const [addressStreetError, setAddressStreetError] = useState('')
  const [addressNumberError, setAddressNumberError] = useState('')
  const [addressComplementError, setAddressComplementError] = useState('')

  const paymentMethod = 'bank_slip'

  const [optionsCities, setOptionsCities] = useState([])
  const optionsUf = citiesAndStates.map((item) => ({
    label: item.nome,
    value: item.sigla,
  }))

  const [buttonLabel, setButtonLabel] = useState('')
  const [buttonIcon, setButtonIcon] = useState(null)
  const [buttonDisabled, setButtonDisabled] = useState(true)

  const fieldsMap = {
    name: { setValue: setName, setError: setNameError },
    email: { setValue: setEmail, setError: setEmailError },
    document: { setValue: setDocument, setError: setDocumentError },
    addressZipCode: { setValue: setAddressZipCode, setError: setAddressZipCodeError },
    addressCity: { setValue: setAddressCity, setError: setAddressCityError },
    addressUf: { setValue: setAddressUf, setError: setAddressUfError },
    addressDistrict: { setValue: setAddressDistrict, setError: setAddressDistrictError },
    addressStreet: { setValue: setAddressStreet, setError: setAddressStreetError },
    addressNumber: { setValue: setAddressNumber, setError: setAddressNumberError },
    addressComplement: { setValue: setAddressComplement, setError: setAddressComplementError },
  }

  const getAddressByCep = (e) => {
    const { value } = e.target

    if (value.length === 9) {
      cep(value).then((response) => {
        const { city, neighborhood, state, street } = response

        const ufIndex = citiesAndStates.findIndex((item) => item.sigla === state)
        const currentUf = citiesAndStates[ufIndex]

        setAddressUf({ label: currentUf.nome, value: currentUf.sigla })
        setAddressCity({ label: city, value: city })

        setAddressDistrict(neighborhood)
        setAddressStreet(street)
      })
    }
  }

  const handleFieldChange = (e) => {
    const { name: fieldName } = e.target
    let { value: fieldValue } = e.target

    const masksMap = {
      addressZipCode: '99999-999',
      cpf: '999.999.999-99',
      cnpj: '99.999.999/9999-99',
    }

    // generic masks
    if (masksMap[fieldName]) {
      fieldValue = msk.fit(fieldValue, masksMap[fieldName])
    }

    // document mask
    if (fieldName === 'document') {
      fieldValue =
        fieldValue.length > 14 ?
          msk.fit(fieldValue, masksMap.cnpj) :
          msk.fit(fieldValue, masksMap.cpf)
    }

    fieldsMap[fieldName].setValue(fieldValue)
    fieldsMap[fieldName].setError('')
  }

  const handleUfChange = ({ selected }) => {
    setAddressUf(selected)
  }

  const handleCityChange = ({ selected }) => {
    setAddressCity(selected)
  }

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()

      if (isSending || isSuccess) {
        return
      }

      if (!name) {
        setNameError('é necessário informar o nome do titular')
        return
      }

      if (!validateEmail(email)) {
        setEmailError('é necessário informar um email válido')
        return
      }

      if (!validateCpf(document) && !validateCnpj(document)) {
        setDocumentError('o documento é inválido')
        return
      }

      if (!addressZipCode) {
        setAddressZipCodeError('é necessário informar o CEP')
        return
      }

      if (!validateCEP(addressZipCode)) {
        setAddressZipCodeError('é necessário informar um CEP válido')
        return
      }

      if (!addressUf) {
        setAddressUfError('é necessário informar o estado')
        return
      }

      if (!addressCity) {
        setAddressCityError('é necessário informar a cidade')
        return
      }

      if (!addressDistrict) {
        addressDistrictError('é necessário informar o bairro')
        return
      }

      if (!addressStreet) {
        setAddressStreetError('é necessário informar a rua')
        return
      }

      if (!addressNumber) {
        setAddressNumberError('é necessário informar o número')
        return
      }

      setIsSending(true)

      const response = await onSubmit({
        name,
        email,
        document,
        addressZipCode,
        addressCity: addressCity.value,
        addressUf: addressUf.value,
        addressDistrict,
        addressStreet,
        addressNumber,
        addressComplement,
        paymentMethod
      })

      setIsSending(false)

      if (response.success) {
        setIsSuccess(true)
      }

      if (response.errorMessage) {
        setErrorMessage(response.errorMessage)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      name,
      email,
      document,
      addressZipCode,
      addressCity,
      addressUf,
      addressDistrict,
      addressStreet,
      addressNumber,
      addressComplement,
      isSending,
      isSuccess,
      paymentMethod
    ],
  )

  useEffect(() => {
    if (!addressUf) {
      return
    }

    const ufIndex = citiesAndStates.findIndex((item) => item.sigla === addressUf.value)

    const currentOptionsCities = citiesAndStates[ufIndex].cidades.map((item) => ({
      label: item,
      value: item,
    }))

    setOptionsCities(currentOptionsCities)
  }, [addressUf])

  useEffect(() => {
    const isFilled =
      name &&
      email &&
      document &&
      addressZipCode &&
      addressDistrict &&
      addressStreet &&
      addressNumber

    setIsFormFilled(isFilled)
  }, [name, email, document, addressZipCode, addressDistrict, addressStreet, addressNumber])

  useEffect(() => {
    if (isSuccess) {
      setButtonIcon('mono_check_filled')
      setButtonLabel('pronto')
      setButtonDisabled(false)
    } else if (isSending) {
      setButtonLabel('processando')
      setButtonDisabled(false)
    } else if (isFormFilled) {
      setButtonIcon(null)
      setButtonLabel(labelButton)
      setButtonDisabled(false)
    } else {
      setButtonIcon(null)
      setButtonLabel(labelButton)
      setButtonDisabled(true)
    }
  }, [isFormFilled, isSending, isSuccess, labelButton])

  return (
    <div>
      <Logo className={style.logo} />

      <Typography className={style.title} variant="displayM">
        {title}
      </Typography>

      <form className={style.form} onSubmit={handleSubmit}>
        <div className={style.content}>
          <FieldText
            className={style.field}
            name="name"
            value={name}
            readOnly={isSending || isSuccess}
            error={nameError}
            onChange={handleFieldChange}
            placeholder="digite seu nome"
            label="nome completo"
          />

          <FieldText
            className={style.field}
            name="email"
            value={email}
            readOnly={isSending || isSuccess}
            error={emailError}
            type="email"
            onChange={handleFieldChange}
            placeholder="digite seu email"
            label="e-mail"
          />

          <FieldText
            className={style.field}
            name="document"
            value={document}
            readOnly={isSending || isSuccess}
            error={documentError}
            onChange={handleFieldChange}
            placeholder="012.345.678-90"
            label="CPF ou CNPJ"
            inputmode="numeric"
          />

          <div className={style.fieldRow}>
            <FieldText
              className={style.field}
              name="addressZipCode"
              value={addressZipCode}
              readOnly={isSending || isSuccess}
              error={addressZipCodeError}
              onChange={handleFieldChange}
              onBlur={getAddressByCep}
              placeholder="86099-101"
              label="CEP"
              inputmode="numeric"
            />

            <FieldSelect
              className={style.field}
              name="addressUf"
              selectedOption={addressUf}
              error={addressUfError}
              onChange={handleUfChange}
              placeholder="selecione"
              label="estado"
              options={optionsUf}
              isSearchable
            />

            <FieldSelect
              className={style.field}
              name="addressCity"
              selectedOption={addressCity}
              error={addressCityError}
              onChange={handleCityChange}
              placeholder="selecione"
              label="cidade"
              options={optionsCities}
              isSearchable
            />

            <FieldText
              className={style.field}
              name="addressDistrict"
              value={addressDistrict}
              readOnly={isSending || isSuccess}
              error={addressDistrictError}
              onChange={handleFieldChange}
              placeholder="digite o bairro"
              label="bairro"
            />
          </div>

          <div className={style.fieldRow}>
            <FieldText
              className={style.field}
              name="addressStreet"
              value={addressStreet}
              readOnly={isSending || isSuccess}
              error={addressStreetError}
              onChange={handleFieldChange}
              placeholder="digite a rua"
              label="rua"
            />

            <FieldText
              className={style.field}
              name="addressNumber"
              value={addressNumber}
              readOnly={isSending || isSuccess}
              error={addressNumberError}
              onChange={handleFieldChange}
              placeholder="digite o número"
              label="número"
              inputmode="numeric"
            />

            <FieldText
              className={style.field}
              name="addressComplement"
              value={addressComplement}
              readOnly={isSending || isSuccess}
              error={addressComplementError}
              onChange={handleFieldChange}
              placeholder="digite o complemento"
              label="complemento"
            />
          </div>
        </div>

        {errorMessage && (
          <CardClean className={style.errorCard} variant="danger">
            <Typography element="p" variant="bodyS">
              {errorMessage}
            </Typography>
          </CardClean>
        )}

        <div className={style.actions}>
          <Button
            variant="primaryGradient"
            type="submit"
            className={style.button}
            disabled={buttonDisabled}
            label={buttonLabel}
            prefixIcon={buttonIcon}
            loading={isSending}
          />
          {creditCardOnClick && (
            <Button
              variant="tertiary"
              className={style.button}
              label="assinar com cartão de crédito"
              onClick={creditCardOnClick}
            />
          )}
        </div>
      </form>
    </div>
  )
}

FormSubscriptionBankSlip.propTypes = {
  creditCardOnClick: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  labelButton: PropTypes.string,
  initialValues: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
  }),
}

FormSubscriptionBankSlip.defaultProps = {
  creditCardOnClick: null,
  title: 'contratar PRIME com boleto',
  labelButton: 'assinar com boleto',
  initialValues: {
    name: '',
    email: '',
  },
}

export default FormSubscriptionBankSlip
