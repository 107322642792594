import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { WrapComponentWithAppStateConsumer } from 'AppContext'
import { Footer } from '@ahazou/react-components'
import NavHeader from 'components/NavHeader'
import Modal from 'components/Modal'
import LoadingScreen from 'components/LoadingScreen'
import style from './style.module.css'

const App = ({ context, children }) => {
  const { state } = context
  const { user, isAppLoading, isAppReady } = state
  const [isContentAvailable, setIsContentAvailable] = useState(false)
  const isAuthenticated = Boolean(user && user.id)

  useEffect(() => {
    if (isAppReady) {
      setIsContentAvailable(true)
    }
  }, [isAppReady])

  return (
    <div>
      {!isAppReady ? <LoadingScreen loading={isAppLoading} /> : null}

      {isContentAvailable && (
        <div
          className={classNames(style.app, {
            [style.external]: !isAuthenticated,
            [style.internal]: isAuthenticated,
          })}
        >
          {isAuthenticated && <NavHeader />}

          <div className={style.content}>{children}</div>

          {isAuthenticated && <Footer />}
          <Modal />
        </div>
      )}
    </div>
  )
}

App.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.shape({
      user: PropTypes.shape(),
      isAppLoading: PropTypes.bool.isRequired,
      isAppReady: PropTypes.bool.isRequired,
    }),
  }).isRequired,
  children: PropTypes.node.isRequired,
}

export default WrapComponentWithAppStateConsumer(App)
