import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect, useRouteMatch, useLocation } from 'react-router-dom'
import { WrapComponentWithAppStateConsumer } from 'AppContext'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import Container from 'components/Container'
import MyAccountNav from 'components/MyAccountNav'
import MyAccountContent from 'components/MyAccountContent'
import MyAccountData from 'pages/MyAccountData'
import MyAccountSubscription from 'pages/MyAccountSubscription'
// import MyAccountSegments from 'pages/MyAccountSegments'

const MyAccount = ({ context }) => {
  const { url } = useRouteMatch()
  const { state } = context
  const { user, isPrime } = state
  const location = useLocation()
  const subscriptionPricing = user?.subscription?.invoice?.price || ''

  if (!isMobile && location.pathname === url) {
    if (isPrime) {
      return <Redirect to="/minha-conta/assinatura" />
    }

    return <Redirect to="/minha-conta/meus-dados" />
  }

  return (
    <>
      <Helmet>
        <title>Ahazou - Minha Conta</title>
      </Helmet>
      <Container fill flex small>
        {(!isMobile || location.pathname === url) && (
          <MyAccountNav
            userName={user.profile.name}
            segmentsNames={user.segments.macro}
            isPrime={isPrime}
            price={isPrime ? subscriptionPricing : process.env.REACT_APP_DEFAULT_PLAN_PRINCING}
          />
        )}

        {(!isMobile || location.pathname !== url) && (
          <MyAccountContent>
            <Switch>
              <Route exact path={`${url}/meus-dados`} component={MyAccountData} />
              <Route exact path={`${url}/assinatura`} component={MyAccountSubscription} />
              {/* <Route exact path={`${url}/temas`} component={MyAccountSegments} /> */}
            </Switch>
          </MyAccountContent>
        )}
      </Container>
    </>
  )
}

MyAccount.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.shape({
      user: PropTypes.shape({
        profile: PropTypes.shape({
          name: PropTypes.string,
        }),
        segments: PropTypes.shape({
          macro: PropTypes.arrayOf(PropTypes.string),
          micro: PropTypes.arrayOf(PropTypes.string),
        }),
        subscription: PropTypes.shape({
          status: PropTypes.string,
          invoice: PropTypes.shape({
            price: PropTypes.number,
          }),
        }),
      }),
      isPrime: PropTypes.bool,
    }),
  }).isRequired,
}

export default WrapComponentWithAppStateConsumer(MyAccount)
