import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import DateTime from 'luxon/src/datetime'
import { WrapComponentWithAppStateConsumer } from 'AppContext'
import processIuguPayment from 'utils/process-iugu-payment'
import { postCancelReasons } from 'utils/firebase'
import { trackEvent } from 'utils/tracker'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import { Typography } from '@ahazou/react-components'
import { postCancelSubscription, putChangePaymentMethod } from 'utils/api/subscription'
import MyAccountSubscriptionManagement from 'components/MyAccountSubscriptionManagement'
import FormSubscriptionBankSlip from 'components/FormSubscriptionBankSlip'
import FormSubscriptionCreditCard from 'components/FormSubscriptionCreditCard'
import FormSubscriptionCancel from 'components/FormSubscriptionCancel'
import AlertSubscriptionCancel from 'components/AlertSubscriptionCancel'
import AlertSubscriptionCancelSuccess from 'components/AlertSubscriptionCancelSuccess'

const MyAccountSubscription = ({ context }) => {
  const { state, modalOpen, modalAlertOpen, modalClose } = context
  const { user, isPrime } = state

  const subscriptionChangeSubmit = useCallback(
    async (data) => {
      return new Promise((resolve) => {
        processIuguPayment(data, (formatedData) => {
          if (formatedData.errors) {
            resolve({ errorMessage: 'por favor, verifique as informações preenchidas' })
            return
          }

          putChangePaymentMethod(formatedData).then((responseSubscription) => {
            if (responseSubscription.status === 200) {
              resolve({ success: true })

              if (formatedData.payableWith === 'credit_card') {
                // credit card
                modalAlertOpen({
                  titleIcon: 'mono_check_filled',
                  title: 'tudo certo!',
                  message:
                    'alteramos seu método de pagamento para cartão de crédito e suas próximas faturas serão creditadas automaticamente na data de expiração da assinatura',
                  closeLabel: 'ok, entendi',
                })
              } else {
                // bank slip
                modalAlertOpen({
                  titleIcon: 'mono_check_filled',
                  title: 'tudo certo!',
                  message: `alteramos seu método de pagamento para boleto e suas próximas faturas serão enviadas para o email <b>${user.profile.email}</b>
                `,
                  closeLabel: 'ok, entendi',
                })
              }
            } else if (responseSubscription.status === 500) {
              resolve({
                errorMessage:
                  'houve um erro ao concluir a contratação, por favor entre em contato com o suporte',
              })
            } else {
              resolve({ errorMessage: 'por favor, verifique as informações preenchidas' })
            }
          })
        })
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  )

  const subscriptionCancelSubmit = useCallback(
    async ({ reason, description, whatsapp }) => {
      const cancelResponse = await postCancelSubscription()

      if (cancelResponse.status === 200) {
        await postCancelReasons({
          date_time: DateTime.local().setLocale('br').toISO(),
          user_id: user.id,
          user_name: user.profile.name,
          user_email: user.profile.email,
          user_contact_email: user.profile.contact_email,
          reason,
          description,
          whatsapp,
        })

        trackEvent('cancel_prime', {
          provider: 'iugu',
          reason,
        })

        modalOpen({
          children: (
            <AlertSubscriptionCancelSuccess
              name={user.profile.name.split(' ')[0]}
              onConfirm={() => {
                modalClose()

                if (isMobile) {
                  window.location.href = 'https://ahazou.app.goo.gl/viewHome'
                }
              }}
            />
          ),
        })

        return { success: true }
      }

      return {
        success: false,
        errorMessage:
          'houve um erro ao efetuar o cancelamento, por favor entre em contato com o suporte',
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  )

  const openModalSubscriptionChangePayment = useCallback(() => {
    const payableWith = user.subscription.invoice.payable_with

    if (payableWith === 'bank_slip') {
      trackEvent('clicked_change_payment', {
        provider: 'iugu',
        method: 'pix',
      })

      modalOpen({
        children: (
          <FormSubscriptionCreditCard
            title="mudar para pix"
            labelButton="mudar pagamento"
            onSubmit={subscriptionChangeSubmit}
            initialValues={{ name: user.profile.name, email: user.profile.contact_email }}
          />
        ),
      })
    } else if (payableWith === 'pix') {
      trackEvent('clicked_change_payment', {
        provider: 'iugu',
        method: 'cc',
      })

      modalOpen({
        children: (
          <FormSubscriptionCreditCard
            title="mudar para cartão de crédito"
            labelButton="mudar pagamento"
            onSubmit={subscriptionChangeSubmit}
            initialValues={{ name: user.profile.name, email: user.profile.contact_email }}
          />
        ),
      })
    } else if (payableWith === 'credit_card') {
      trackEvent('clicked_change_payment', {
        provider: 'iugu',
        method: 'bank_slip',
      })

      modalOpen({
        children: (
          <FormSubscriptionBankSlip
            title="mudar para boleto"
            labelButton="mudar pagamento"
            onSubmit={subscriptionChangeSubmit}
            initialValues={{ name: user.profile.name, email: user.profile.contact_email }}
          />
        ),
      })
    }
  }, [modalOpen, subscriptionChangeSubmit, user])

  const openModalSubscriptionChangeCreditCard = useCallback(() => {
    modalOpen({
      children: (
        <FormSubscriptionCreditCard
          title="mudar cartão de crédito"
          labelButton="mudar cartão de crédito"
          onSubmit={subscriptionChangeSubmit}
          initialValues={{ name: user.profile.name, email: user.profile.contact_email }}
        />
      ),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionChangeSubmit])

  const openModalSubscriptionCancel = useCallback(() => {
    trackEvent('clicked_cancel_prime', {
      provider: 'iugu',
    })

    const openModalFormCancel = () => {
      modalOpen({
        children: (
          <FormSubscriptionCancel
            onSubmit={subscriptionCancelSubmit}
            onCancel={() => modalClose()}
          />
        ),
      })
    }

    modalOpen({
      children: (
        <AlertSubscriptionCancel onConfirm={openModalFormCancel} onCancel={() => modalClose()} />
      ),
    })
  }, [modalOpen, subscriptionCancelSubmit, modalClose])

  if (!isPrime) {
    return <Redirect to="/assinar" />
  }

  return (
    <>
      <Helmet>
        <title>Ahazou - Gerenciar Assinatura</title>
      </Helmet>
      <Typography element="h2" variant="displayL">
        Ahazou PRIME
      </Typography>

      <MyAccountSubscriptionManagement
        onChangePaymentClick={openModalSubscriptionChangePayment}
        onChangeCreditCardClick={openModalSubscriptionChangeCreditCard}
        onCancelClick={openModalSubscriptionCancel}
        {...user.subscription}
      />
    </>
  )
}

MyAccountSubscription.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.shape({
      user: PropTypes.shape({
        id: PropTypes.string,
        profile: PropTypes.shape({
          name: PropTypes.string,
          email: PropTypes.string,
          contact_email: PropTypes.string,
        }),
        segments: PropTypes.shape({
          macro: PropTypes.arrayOf(PropTypes.string),
          micro: PropTypes.arrayOf(PropTypes.string),
        }),
        subscription: PropTypes.shape({
          payment_method: PropTypes.shape({
            type: PropTypes.oneOf(['credit_card', 'bank_slip', 'pix']),
          }),
          invoice: PropTypes.shape({
            payable_with: PropTypes.oneOf(['credit_card', 'bank_slip', 'pix']),
          }),
        }),
      }),
      isPrime: PropTypes.bool,
    }),
    modalOpen: PropTypes.func.isRequired,
    modalAlertOpen: PropTypes.func.isRequired,
    modalClose: PropTypes.func.isRequired,
  }).isRequired,
}

export default WrapComponentWithAppStateConsumer(MyAccountSubscription)
