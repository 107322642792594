/* eslint-disable react/no-unused-state */
import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import { getLocalStorageItem } from 'utils/storage-handler'
import { getUser, listenUserChange } from 'utils/firebase'
import * as tracker from 'utils/tracker'

const AppContext = React.createContext()

const getPrimeStatus = (subscription) => {
  if (!subscription) {
    return false
  }

  const expiresAt = DateTime.fromISO(subscription.expires_at)
  const gracePeriodAt = DateTime.fromISO(subscription.grace_period_at)
  const tomorrow = DateTime.local().minus({ days: 1 })

  if (expiresAt.diff(tomorrow).days > 0) {
    return true
  }

  if (gracePeriodAt.diff(tomorrow).days > 0) {
    return true
  }

  if (subscription.status === 'active') {
    return true
  }

  if (subscription.invoice && subscription.invoice.status === 'pending') {
    return true
  }

  return false
}

export const WrapComponentWithAppStateConsumer = (Component) => (props) => (
  <AppContext.Consumer>
    {(context) => <Component {...props} context={context} />}
  </AppContext.Consumer>
)

let listenerUserUnsubscribe = null

class AppStateProvider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isAppLoading: true,
      isAppReady: false,
      modalOpened: false,
      modalProps: {},
      user: {},
      isPrime: false,
      currentPlan: {
        price: parseFloat(process.env.REACT_APP_DEFAULT_PLAN_PRINCING),
        name: process.env.REACT_APP_DEFAULT_PLAN_NAME,
      },
    }
  }

  componentDidMount() {
    this.initializeUser()
  }

  initializeUser = async () => {
    const currentUser = getLocalStorageItem('user') || {}
    const hasUserSession = Boolean(currentUser.id)
    const isLoginRoute = /\/login\/.+/.test(window.location.pathname)

    if (hasUserSession && !isLoginRoute) {
      this.setState({ isAppLoading: true })
      this.setState({ isAppLoading: false })

      const user = await getUser(currentUser.id)
      this.setState({ user }, () => {
        this.setIsPrime()
        this.appLoadingSet(false)
      })
    }

    this.setState({ isAppLoading: false })
    this.setState({ isAppReady: true })
  }

  listenUser = (userId) => {
    listenerUserUnsubscribe = listenUserChange(userId, (user) => {
      this.setState({ user }, () => {
        this.setIsPrime()
      })
    })
  }

  appLoadingSet = (isLoading) => {
    if (isLoading) {
      this.setState({ isAppLoading: true })
      this.setState({ isAppReady: false })
    } else {
      this.setState({ isAppLoading: false })

      window.setTimeout(() => {
        this.setState({ isAppReady: true })
      }, 200) // timeout needed to perform css transitions
    }
  }

  setIsPrime = () => {
    const { user } = this.state
    if (user !== null) {
      this.setState({ isPrime: getPrimeStatus(user.subscription || {}) })
    } else {
      this.setState({ isPrime: false })
    }
  }

  modalOpen = (modalProps) => {
    this.setState({ modalProps })

    window.setTimeout(() => {
      this.setState({ modalOpened: true })
    }, 10) // timeout needed to perform css transitions
  }

  modalAlertOpen = (alert) => {
    this.setState({ modalProps: { blocked: true, alert } })

    window.setTimeout(() => {
      this.setState({ modalOpened: true })
    }, 10) // timeout needed to perform css transitions
  }

  modalClose = () => {
    this.setState({ modalOpened: false })
    window.location.reload(true)

    window.setTimeout(() => {
      this.setState({ modalProps: {} })
    }, 200) // timeout needed to perform css transitions
  }

  userSet = (user) => {
    this.setState({ user }, () => {
      this.setIsPrime()

      tracker.setUser(user)

      if (!listenerUserUnsubscribe) {
        this.listenUser(user.id)
      }
    })
  }

  userClear = () => {
    this.setState({ user: {} })
    this.setState({ isPrime: false })
    tracker.clearUser()

    if (listenerUserUnsubscribe) {
      listenerUserUnsubscribe()
      listenerUserUnsubscribe = null
    }
  }

  currentPlanSet = (currentPlan) => {
    this.setState({ currentPlan })
  }

  render() {
    const { children } = this.props

    return (
      <AppContext.Provider
        value={{
          state: this.state,
          modalOpen: this.modalOpen,
          modalAlertOpen: this.modalAlertOpen,
          modalClose: this.modalClose,
          userSet: this.userSet,
          userClear: this.userClear,
          currentPlanSet: this.currentPlanSet,
          appLoadingSet: this.appLoadingSet,
        }}
      >
        {children}
      </AppContext.Provider>
    )
  }
}

AppStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { AppStateProvider }
