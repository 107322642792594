export function setUser(user) {
  if (window.clevertap) {
    window.clevertap.profile.push({
      identity: user.id,
    })
  }
}

export function clearUser() {
  if (window.clevertap) {
    window.clevertap.clear()
  }
}

export function trackEvent(eventName, eventProps) {
  if (window.clevertap) {
    window.clevertap.event.push(eventName, eventProps)
  }
}

export function init() {
  return new Promise((resolve) => {
    window.clevertap = {
      account: [{ id: process.env.REACT_APP_CLEVERTAP_ACCOUNT_ID }],
      event: [],
      profile: [],
      onUserLogin: [],
      notifications: [],
      privacy: [{ optOut: false }, { useIP: true }],
    }

    const wzrk = document.createElement('script')
    wzrk.type = 'text/javascript'
    wzrk.async = true
    wzrk.src = `${
      document.location.protocol === 'https:' ?
        'https://d2r1yp2w7bby2u.cloudfront.net' :
        'http://static.clevertap.com'
    }/js/a.js`

    wzrk.addEventListener('load', resolve)

    const s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(wzrk, s)
  })
}
