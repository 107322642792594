import React from 'react'
import PropTypes from 'prop-types'
import formatCurrency from 'utils/format-currency'
import Container from 'components/Container'
import { Icon, Button, Typography, Card } from '@ahazou/react-components'
import style from './style.module.css'

const benefits = [
  '<b style="color: #000;">otimize seu tempo</b> com mais de 300 mil posts prontos',
  '<b style="color: #000;">transmita profissionalismo</b> com conteúdo sem marca d’água',
  '<b style="color: #000;">planeje seu dia,</b> aproveite as datas importantes e <b style="color: #000;">venda mais</b>',
]

const SectionSubscriptionBox = ({ price, name, plan, onSubscriptionClick, isWebApp }) => {
  let isYearly
  let labelBuyButton

  if (plan === process.env.REACT_APP_PLAN_ANUAL) {
    labelBuyButton = 'assinar PRIME anual'

    return (
      <Container className={style.container}>
        <Card className={style.card}>
          <Typography className={style.title} element="h4" variant="displayM">
            PRIME anual
          </Typography>

          <ul className={style.list}>
            {benefits.map((benefit) => (
              <li key={benefit} className={style.item}>
                <Icon className={style.itemIcon} size="l" icon="mono_check_less" />
                <Typography className={style.itemText} variant="bodyS" innerHTML={benefit} />
              </li>
            ))}
          </ul>

          <Typography className={style.currentPrice} element="span" variant="displayL">
            R$ 199,90/ano
          </Typography>

          <Button className={style.button} onClick={onSubscriptionClick} label={labelBuyButton} />
        </Card>
      </Container>
    )
  }

  if (plan === process.env.REACT_APP_PLAN_ANUAL_PRO) {
    labelBuyButton = 'assinar PRO anual'

    return (
      <Container className={style.container}>
        <Card className={style.card}>
          <Typography className={style.title} element="h4" variant="displayM">
            PRO anual
          </Typography>

          <ul className={style.list}>
            {benefits.map((benefit) => (
              <li key={benefit} className={style.item}>
                <Icon className={style.itemIcon} size="l" icon="mono_check_less" />
                <Typography className={style.itemText} variant="bodyS" innerHTML={benefit} />
              </li>
            ))}
          </ul>

          <Typography className={style.currentPrice} element="span" variant="displayL">
            R$ 299,90/ano
          </Typography>

          <Button className={style.button} onClick={onSubscriptionClick} label={labelBuyButton} />
        </Card>
      </Container>
    )
  }

  if (plan === process.env.REACT_APP_PLAN_ANUAL_BLACK_FRIDAY) {
    isYearly = false
    labelBuyButton = 'assinar'

    return (
      <Container className={style.container}>
        <Card className={style.card}>
          <Typography className={style.title} element="h4" variant="displayM">
            Black Friday
          </Typography>

          <ul className={style.list}>
            {benefits.map((benefit) => (
              <li key={benefit} className={style.item}>
                <Icon className={style.itemIcon} size="l" icon="mono_check_less" />
                <Typography className={style.itemText} variant="bodyS" innerHTML={benefit} />
              </li>
            ))}
          </ul>

          <Typography className={style.currentPrice} element="span" variant="displayL">
            R$ {formatCurrency(price)}
            {isYearly ? ',90/ano' : ',90/mês'}
          </Typography>

          <Button className={style.button} onClick={onSubscriptionClick} label={labelBuyButton} />
        </Card>
      </Container>
    )
  }

  if (isWebApp) {
    labelBuyButton = 'assinar'
  } else {
    labelBuyButton = 'assinar PRIME'
  }

  return (
    <Container className={style.container}>
      <Card className={style.card}>
        <Typography className={style.title} element="h4" variant="displayM">
          {name}
        </Typography>

        <ul className={style.list}>
          {benefits.map((benefit) => (
            <li key={benefit} className={style.item}>
              <Icon className={style.itemIcon} size="l" icon="mono_check_less" />
              <Typography className={style.itemText} variant="bodyS" innerHTML={benefit} />
            </li>
          ))}
        </ul>

        <Typography className={style.currentPrice} element="span" variant="displayL">
          R$ {formatCurrency(price)},90/mês
        </Typography>

        <Button className={style.button} onClick={onSubscriptionClick} label={labelBuyButton} />
      </Card>
    </Container>
  )
}

SectionSubscriptionBox.propTypes = {
  onSubscriptionClick: PropTypes.func.isRequired,
  price: PropTypes.number,
  name: PropTypes.string.isRequired,
  plan: PropTypes.string,
  isWebApp: PropTypes.bool,
}

SectionSubscriptionBox.defaultProps = {
  price: 99,
  plan: 'prime29',
  isWebApp: false,
}

export default SectionSubscriptionBox
