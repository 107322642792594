import axios from 'axios'
import { getLocalStorageItem } from 'utils/storage-handler'

export default async function({ baseURL, method, url, data }) {
  const user = getLocalStorageItem('user') || {}
  const headers = {}

  if (user.id) {
    headers.Authorization = `Bearer ${user.id}`
  }

  try {
    const response = await axios({
      baseURL,
      method,
      url,
      data,
      headers,
    })

    return { ...response.data, status: response.status }
  } catch (error) {
    if (error.response) {
      return error.response
    }

    return { ...error, status: 500 }
  }

  // const response = await axios({
  //   baseURL,
  //   method,
  //   url,
  //   data,
  //   headers,
  // })

  // return {
  //   status: response.status,
  //   ...response.data,
  // }
}
