import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Redirect, useParams } from 'react-router-dom'
import { getPlan, postSubscription, postChargeYearly } from 'utils/api/subscription'
import processIuguPayment from 'utils/process-iugu-payment'
import { trackEvent } from 'utils/tracker'
import { WrapComponentWithAppStateConsumer } from 'AppContext'
import { Helmet } from 'react-helmet'
import BannerSubscription from 'components/BannerSubscription'
import SectionSubscriptionBox from 'components/SectionSubscriptionBox'
import SectionSubscriptionBenefits from 'components/SectionSubscriptionBenefits'
import SectionSubscriptionTestimonials from 'components/SectionSubscriptionTestimonials'
import FormSubscriptionBankSlip from 'components/FormSubscriptionBankSlip'
import FormSubscriptionCreditCard from 'components/FormSubscriptionCreditCard'
import FormSubscriptionPix from '../components/FormSubscriptionPix'
import { getLocalStorageItem } from '../utils/storage-handler'

const { Iugu } = window
Iugu.setAccountID(process.env.REACT_APP_IUGU_ACCOUNT_ID)

const redirectToWebApp = () => {
  const isWebApp = getLocalStorageItem('webApp')
  return !!isWebApp
}

const Subscription = ({ context }) => {
  const { modalOpen, modalAlertOpen, modalClose, currentPlanSet, state } = context
  const { user, isPrime, currentPlan } = state
  const { planIdentifier, payableWith } = useParams()
  const [plan, setPlan] = useState(planIdentifier || process.env.REACT_APP_DEFAULT_PLAN)

  const subscriptionSubmit = useCallback(
    async (data) => {
      let methodTrack
      if (data.paymentMethod === 'credit_card') {
        methodTrack = 'cc'
      } else if (data.paymentMethod === 'bank_slip') {
        methodTrack = 'bank_slip'
      } else {
        methodTrack = 'pix'
      }

      const urlWebApp = process.env.REACT_APP_LINK_WEB_APP

      await trackEvent('clicked_payment', {
        provider: 'iugu',
        method: methodTrack,
        plan,
      })

      return new Promise((resolve) => {
        processIuguPayment({ ...data, user, plan }, (formatedData) => {
          if (formatedData.errors) {
            resolve({ errorMessage: 'por favor, verifique as informações preenchidas' })
            return
          }

          if (
            (plan === process.env.REACT_APP_PLAN_ANUAL ||
              plan === process.env.REACT_APP_PLAN_ANUAL_BLACK_FRIDAY) &&
            formatedData.months > 1
          ) {
            postChargeYearly(formatedData).then((responseCharge) => {
              if (responseCharge.status === 200) {
                resolve({ success: true })
                if (formatedData.payableWith === 'credit_card') {
                  // credit card
                  modalAlertOpen({
                    titleIcon: 'mono_check_filled',
                    title: 'tudo certo!',
                    message:
                      'agora sua assinatura está ativa e você pode aproveitar todos os benefícios exclusivos',
                    closeLabel: redirectToWebApp() ?
                      'aproveitar o Ahazou' :
                      'aproveitar Ahazou PRIME',
                    actionsButtons: redirectToWebApp() ?
                      [
                          {
                            element: 'a',
                            label: 'voltar ao app',
                            href: urlWebApp,
                            target: '_blank',
                          },
                        ] :
                      [],
                  })
                }
              } else if (responseCharge.status === 500) {
                resolve({
                  errorMessage:
                    'houve um erro ao concluir a contratação, por favor entre em contato com o suporte',
                })
              } else {
                resolve({ errorMessage: 'por favor, verifique as informações preenchidas' })
              }
            })
          } else if (plan === process.env.REACT_APP_PLAN_ANUAL_PRO && formatedData.months > 1) {
            postChargeYearly(formatedData).then((responseCharge) => {
              if (responseCharge.status === 200) {
                resolve({ success: true })
                if (formatedData.payableWith === 'credit_card') {
                  // credit card
                  modalAlertOpen({
                    titleIcon: 'mono_check_filled',
                    title: 'tudo certo!',
                    message:
                      'agora sua assinatura PRO está ativa e você pode aproveitar todos os benefícios exclusivos',
                    closeLabel: 'aproveitar Ahazou PRO',
                    actionsButtons: redirectToWebApp() ?
                      [
                          {
                            element: 'a',
                            label: 'voltar ao app',
                            href: urlWebApp,
                            target: '_blank',
                          },
                        ] :
                      [],
                  })
                }
              } else if (responseCharge.status === 500) {
                resolve({
                  errorMessage:
                    'houve um erro ao concluir a contratação, por favor entre em contato com o suporte',
                })
              } else {
                resolve({ errorMessage: 'por favor, verifique as informações preenchidas' })
              }
            })
          } else {
            postSubscription(formatedData).then((responseSubscription) => {
              if (responseSubscription.status === 200) {
                resolve({ success: true })
                if (formatedData.payableWith === 'credit_card') {
                  // credit card
                  modalAlertOpen({
                    titleIcon: 'mono_check_filled',
                    title: 'tudo certo!',
                    message:
                      'agora sua assinatura esta ativa e você pode aproveitar todos os benefícios exclusivos',
                    closeLabel: redirectToWebApp() ?
                      'aproveitar o Ahazou' :
                      'aproveitar o Ahazou PRIME',
                    actionsButtons: redirectToWebApp() ?
                      [
                          {
                            element: 'a',
                            label: 'voltar ao app',
                            href: urlWebApp,
                            target: '_blank',
                          },
                        ] :
                      [],
                  })
                } else if (formatedData.payableWith === 'bank_slip') {
                  // bank slip
                  modalAlertOpen({
                    titleIcon: 'mono_check_filled',
                    title: 'quase lá!',
                    message: `
                  ${
                    user.profile.name.split(' ')[0]
                  }, faltam poucos passos para você fazer parte da família Ahazou.<br /><br />
                  você receberá um email com o boleto em <b>${user.profile.email}</b>
                `,
                    closeLabel: 'ok, entendi',
                    actionsButtons:
                      // eslint-disable-next-line no-nested-ternary
                      redirectToWebApp() ?
                        [
                            {
                              element: 'a',
                              label: 'voltar ao app',
                              href: urlWebApp,
                              target: '_blank',
                            },
                          ] :
                        responseSubscription.invoice_url ?
                        [
                            {
                              element: 'a',
                              label: 'ver boleto',
                              href: responseSubscription.invoice_url,
                              target: '_blank',
                            },
                          ] :
                        [],
                  })
                } else if (formatedData.payableWith === 'pix') {
                  // bank slip
                  modalAlertOpen({
                    titleIcon: 'mono_check_filled',
                    title: 'quase lá!',
                    message: `
                  ${
                    user.profile.name.split(' ')[0]
                  }, faltam poucos passos para você fazer parte da família Ahazou.<br /><br />
                  você receberá um email com os dados do pix em <b>${user.profile.email}</b>
                `,
                    closeLabel: 'ok, entendi',
                    actionsButtons:
                      // eslint-disable-next-line no-nested-ternary
                      redirectToWebApp() ?
                        [
                            {
                              element: 'a',
                              label: 'voltar ao app',
                              href: urlWebApp,
                              target: '_blank',
                            },
                          ] :
                        responseSubscription.invoice_url ?
                        [
                            {
                              element: 'a',
                              label: 'ver boleto',
                              href: responseSubscription.invoice_url,
                              target: '_blank',
                            },
                          ] :
                        [],
                  })
                }
              } else if (responseSubscription.status === 500) {
                resolve({
                  errorMessage:
                    'houve um erro ao concluir a contratação, por favor entre em contato com o suporte',
                })
              } else {
                resolve({ errorMessage: 'por favor, verifique as informações preenchidas' })
              }
            })
          }
        })
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [plan, user],
  )

  const openModalSubscription = (method = 'cc') => {
    if (method === 'bank_slip') {
      modalOpen({
        children: (
          <FormSubscriptionBankSlip
            onSubmit={subscriptionSubmit}
            creditCardOnClick={async () => {
              await trackEvent('method_plan', { method_plan: 'cc', provider: 'iugu' })
              openModalSubscription('cc')
            }}
            finishOnClick={() => modalClose()}
            initialValues={{ name: user.profile.name, email: user.profile.contact_email }}
          />
        ),
      })
    } else if (method === 'cc') {
      modalOpen({
        children: (
          <FormSubscriptionCreditCard
            onSubmit={subscriptionSubmit}
            pixSlipOnClick={async () => {
              await trackEvent('method_plan', { method_plan: 'pix', provider: 'iugu' })
              openModalSubscription('pix')
            }}
            finishOnClick={() => modalClose()}
            initialValues={{ name: user.profile.name, email: user.profile.contact_email, plan }}
          />
        ),
      })
    } else if (method === 'pix') {
      modalOpen({
        children: (
          <FormSubscriptionPix
            onSubmit={subscriptionSubmit}
            bankSlipOnClick={async () => {
              await trackEvent('method_plan', { method_plan: 'bank_slip', provider: 'iugu' })
              openModalSubscription('bank_slip')
            }}
            cardOnClick={async () => {
              await trackEvent('method_plan', { method_plan: 'cc', provider: 'iugu' })
              openModalSubscription('cc')
            }}
            finishOnClick={() => modalClose()}
            initialValues={{ name: user.profile.name, email: user.profile.contact_email }}
          />
        ),
      })
    }
  }

  useEffect(() => {
    const getPlanInfo = async () => {
      const currentPlanResponse = await getPlan(plan)

      if (currentPlanResponse.status === 200) {
        currentPlanSet({
          price: currentPlanResponse.price,
          name: currentPlanResponse.name,
          plan,
        })
        await trackEvent('want_to_be_prime', { plan, provider: 'iugu' })
        await trackEvent('chose_plan', { plan, provider: 'iugu' })
      } else {
        currentPlanSet({
          price: parseFloat(process.env.REACT_APP_DEFAULT_PLAN_PRINCING),
          name: process.env.REACT_APP_DEFAULT_PLAN,
          plan,
        })
        setPlan(process.env.REACT_APP_DEFAULT_PLAN)
        await trackEvent('want_to_be_prime', {
          plan: process.env.REACT_APP_DEFAULT_PLAN,
          provider: 'iugu',
        })
        await trackEvent('chose_plan', {
          plan: process.env.REACT_APP_DEFAULT_PLAN,
          provider: 'iugu',
        })
      }

      if (payableWith) {
        openModalSubscription(payableWith)
      }
    }

    if (plan && !isPrime) {
      getPlanInfo().then((r) => r)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isPrime) {
    return <Redirect to="/minha-conta/assinatura" />
  }

  return (
    <>
      <Helmet>
        <title>Ahazou</title>
      </Helmet>

      <BannerSubscription />

      <SectionSubscriptionBox
        onSubscriptionClick={async () => {
          await trackEvent('method_plan', { method_plan: 'pix', provider: 'iugu' })
          openModalSubscription('pix')
        }}
        price={currentPlan.price}
        name={currentPlan.name}
        plan={currentPlan.plan}
        isWebApp={redirectToWebApp()}
      />

      <SectionSubscriptionBenefits />

      <SectionSubscriptionTestimonials
        onSubscriptionClick={async () => {
          await trackEvent('method_plan', { method_plan: 'pix', provider: 'iugu' })
          openModalSubscription('pix')
        }}
      />
    </>
  )
}

Subscription.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.shape({
      user: PropTypes.shape({
        id: PropTypes.string,
        profile: PropTypes.shape({
          name: PropTypes.string,
          email: PropTypes.string,
          contact_email: PropTypes.string,
        }),
        segments: PropTypes.shape({
          macro: PropTypes.arrayOf(PropTypes.string),
          micro: PropTypes.arrayOf(PropTypes.string),
        }),
        subscription: PropTypes.shape(),
      }),
      isPrime: PropTypes.bool,

      currentPlan: PropTypes.shape({
        name: PropTypes.string,
        plan: PropTypes.string,
        price: PropTypes.number,
      }),
    }),
    modalOpen: PropTypes.func.isRequired,
    modalAlertOpen: PropTypes.func.isRequired,
    modalClose: PropTypes.func.isRequired,
    currentPlanSet: PropTypes.func.isRequired,
  }).isRequired,
}

export default WrapComponentWithAppStateConsumer(Subscription)
