import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import style from './style.module.css'

const Container = ({ className, children, fill, flex, small, element: Element, ...rest }) => {
  return (
    <Element
      className={classNames(style.container, className, {
        [style.fill]: fill,
        [style.flex]: flex,
        [style.small]: small,
      })}
      {...rest}
    >
      {children}
    </Element>
  )
}

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  element: PropTypes.string,
  fill: PropTypes.bool,
  flex: PropTypes.bool,
  small: PropTypes.bool,
}

Container.defaultProps = {
  element: 'div',
  className: null,
  fill: false,
  flex: false,
  small: false,
}

export default Container
