/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { Typography, CardClean, Icon } from '@ahazou/react-components'
import style from './style.module.css'

const CardBenefits = ({ items, icon }) => {
  return (
    <CardClean className={style.card}>
      <ul className={style.list}>
        {items.map((benefit) => (
          <li key={benefit} className={style.item}>
            <Icon className={style.icon} icon={icon} size="m" />
            <Typography className={style.text} variant="bodyS">
              {benefit}
            </Typography>
          </li>
        ))}
      </ul>
    </CardClean>
  )
}

CardBenefits.propTypes = {
  icon: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
}

CardBenefits.defaultProps = {
  icon: 'mono_check_less',
  items: ['acesso ilimitado ao conteúdo', 'sugestões dos melhores posts', 'calendário de posts'],
}

export default CardBenefits
