import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import msk from 'msk'
import { Button, CardClean, FieldText, FieldSelect, Typography } from '@ahazou/react-components'
import style from './style.module.css'

const optionsReasons = [
  {
    label: 'o preço da assinatura é elevado',
    value: 'expensive',
    warningDescription:
      'você sabia que com o PRIME você tem acesso a mais de 60 mil posts gastando apenas 1 real por dia? Esse valor é até 10 vezes mais barato do que contratar um profissional para fazer apenas 1 postagem.',
    labelDescription: null,
    descriptionRequired: false,
    labelWhatsapp:
      'deixe seu whatsapp que nós enviaremos promoções e quem sabe nos vemos no futuro ❤',
  },
  {
    label: 'o conteúdo não é relevante para mim',
    value: 'content',
    warningDescription: null,
    labelDescription: 'o que você não encontrou?',
    descriptionRequired: true,
    labelWhatsapp:
      'deixe seu whatsapp que nós enviaremos promoções e quem sabe nos vemos no futuro ❤',
  },
  {
    label: 'o app apresenta erros',
    value: 'bugs',
    warningDescription: null,
    labelDescription: 'qual erro você encontrou no app?',
    descriptionRequired: true,
    labelWhatsapp:
      'deixe seu whatsapp caso queira ser avisado sobre novas versões da app e quem sabe nos vemos no futuro ❤',
  },
  {
    label: 'corte de gastos devido a quarentena (COVID-19)',
    value: 'COVID-19',
    warningDescription:
      'entendemos seu motivo e estamos criando no nosso instagram e blog conteúdos que ajudem nossos clientes com alternativas de funcionamento, dicas da quarentena e mensagens positivas.',
    labelDescription: null,
    descriptionRequired: false,
    labelWhatsapp:
      'deixe seu Whatsapp aqui em baixo que nós voltamos a nos falar quando tudo isso passar :)',
  },
  {
    label: 'outro motivo',
    value: 'other',
    warningDescription: null,
    labelDescription: 'conte-nos o motivo do cancelamento',
    descriptionRequired: true,
    labelWhatsapp:
      'deixe seu whatsapp que nós enviaremos promoções e quem sabe nos vemos no futuro ❤',
  },
]

const FormSubscriptionCancel = ({ onSubmit, onCancel }) => {
  const [isFormFilled, setIsFormFilled] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [reason, setReason] = useState('')
  const [description, setDescription] = useState('')
  const [whatsapp, setWhatsapp] = useState('')

  const [buttonLabel, setButtonLabel] = useState('')
  const [buttonIcon, setButtonIcon] = useState(null)
  const [buttonDisabled, setButtonDisabled] = useState(true)

  const fieldsMap = {
    description: { setValue: setDescription },
    whatsapp: { setValue: setWhatsapp },
  }

  const handleFieldChange = (e) => {
    const { name: fieldName } = e.target
    let { value: fieldValue } = e.target

    const masksMap = {
      whatsapp: '(99) 99999-9999',
    }

    // generic masks
    if (masksMap[fieldName]) {
      fieldValue = msk.fit(fieldValue, masksMap[fieldName])
    }

    // document mask
    if (fieldName === 'document') {
      fieldValue =
        fieldValue.length > 14 ?
          msk.fit(fieldValue, masksMap.cnpj) :
          msk.fit(fieldValue, masksMap.cpf)
    }

    fieldsMap[fieldName].setValue(fieldValue)
  }

  const handleReasonChange = ({ selected }) => {
    setReason(selected)
  }

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()

      if (isSending || isSuccess) {
        return
      }

      setIsSending(true)

      const response = await onSubmit({
        reason: reason.value,
        description: description ? `"${reason.labelDescription}" \n ${description}` : null,
        whatsapp,
      })

      setIsSending(false)

      if (response.success) {
        setIsSuccess(true)
      }

      if (response.errorMessage) {
        setErrorMessage(response.errorMessage)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reason, description, whatsapp, isSending, isSuccess],
  )

  useEffect(() => {
    const isFilled =
      (reason && !reason.descriptionRequired) ||
      (reason && reason.labelDescription && description.length > 5)

    setIsFormFilled(isFilled)
  }, [reason, description])

  useEffect(() => {
    if (isSuccess) {
      setButtonIcon('mono_check_filled')
      setButtonLabel('pronto')
      setButtonDisabled(false)
    } else if (isSending) {
      setButtonLabel('processando')
      setButtonDisabled(false)
    } else if (isFormFilled) {
      setButtonIcon(null)
      setButtonLabel('continuar cancelamento')
      setButtonDisabled(false)
    } else {
      setButtonIcon(null)
      setButtonLabel('continuar cancelamento')
      setButtonDisabled(true)
    }
  }, [isFormFilled, isSending, isSuccess])

  return (
    <div className={style.container}>
      <Typography className={style.title} variant="displayM" element="h4">
        cancelar assinatura Ahazou PRIME
      </Typography>

      <form className={style.form} onSubmit={handleSubmit}>
        <div className={style.content}>
          <Typography className={style.text} variant="bodyS" element="p">
            ei, conta pra gente o principal motivo que o levou a querer cancelar, assim podemos
            sempre melhorar o Ahazou.
          </Typography>

          <Typography className={style.text} variant="displayM" element="p">
            selecione o principal motivo do cancelamento
          </Typography>

          <FieldSelect
            className={style.field}
            name="reason"
            selectedOption={reason}
            onChange={handleReasonChange}
            placeholder="Selecione"
            options={optionsReasons}
          />

          {reason.warningDescription && (
            <CardClean variant="warning" className={style.warning}>
              <Typography variant="bodyS">{reason.warningDescription}</Typography>
            </CardClean>
          )}

          {reason.labelDescription && (
            <FieldText
              className={style.field}
              name="description"
              value={description}
              readOnly={isSending || isSuccess}
              onChange={handleFieldChange}
              label={`${reason.labelDescription}*`}
            />
          )}

          {reason.value && reason.labelWhatsapp && (
            <>
              <Typography className={style.text} variant="bodyS" element="p">
                {reason.labelWhatsapp}
              </Typography>

              <Typography className={style.text} variant="displayM" element="p">
                vamos manter o contato?
              </Typography>

              <FieldText
                className={style.field}
                name="whatsapp"
                value={whatsapp}
                readOnly={isSending || isSuccess}
                onChange={handleFieldChange}
                label="whatsapp"
                placeholder="(11) 99911-2233"
              />
            </>
          )}
        </div>

        {errorMessage && (
          <CardClean className={style.errorCard} variant="danger">
            <Typography element="p" variant="bodyS">
              {errorMessage}
            </Typography>
          </CardClean>
        )}

        <div className={style.actions}>
          <Button
            variant="primary"
            type="submit"
            className={style.button}
            disabled={buttonDisabled}
            label={buttonLabel}
            prefixIcon={buttonIcon}
            loading={isSending}
          />

          <Button
            variant="secondary"
            type="button"
            className={style.button}
            disabled={isSending || isSuccess}
            label="fechar"
            onClick={onCancel}
          />
        </div>
      </form>
    </div>
  )
}

FormSubscriptionCancel.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default FormSubscriptionCancel
