import { getLocalStorageItem } from 'utils/storage-handler'
import request from './request'

export function getPlan(planIdentifier = process.env.REACT_APP_DEFAULT_PLAN) {
  return request({
    baseURL: process.env.REACT_APP_API_SUBSCRIPTION_URL,
    method: 'GET',
    url: `/iugu/plan?plan=${planIdentifier}`,
  })
}

export function postSubscription(data) {
  return request({
    baseURL: process.env.REACT_APP_API_SUBSCRIPTION_URL,
    method: 'POST',
    url: `/subscription`,
    data,
  })
}

export function postChargeYearly(data) {
  return request({
    baseURL: process.env.REACT_APP_API_SUBSCRIPTION_YEARLY_URL,
    method: 'POST',
    url: `/create-charge`,
    data,
  })
}

export function postCancelSubscription() {
  const user = getLocalStorageItem('user') || {}

  return request({
    baseURL: process.env.REACT_APP_API_SUBSCRIPTION_URL,
    method: 'POST',
    url: `/users/${user.id}/subscriptions/iugu/cancel`,
  })
}

export function syncUser(userId) {
  return request({
    baseURL: process.env.REACT_APP_API_SUBSCRIPTION_URL,
    method: 'GET',
    url: `/subscription/sync-firebase?userId=${userId}`,
  })
}

export function putChangePaymentMethod(data) {
  const user = getLocalStorageItem('user') || {}

  return request({
    baseURL: process.env.REACT_APP_API_SUBSCRIPTION_URL,
    method: 'PUT',
    url: `/users/${user.id}/subscriptions/iugu/change-payment-method`,
    data,
  })
}
