export function setUser(user) {
  if (window.Intercom) {
    window.Intercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      email: user.profile.email,
      name: user.profile.name,
      user_id: user.id,
    })
  }
}

export function clearUser() {
  if (window.Intercom) {
    window.Intercom('shutdown')
  }
}

export function trackEvent(eventName, eventProps) {
  if (window.Intercom) {
    window.Intercom('trackEvent', eventName, eventProps)
  }
}

export function init() {
  return new Promise((resolve) => {
    window.intercomSettings = {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      hide_default_launcher: true,
      alignment: 'right',
      horizontal_padding: 20,
      vertical_padding: 20,
    }

    const w = window
    const ic = w.Intercom
    if (typeof ic === 'function') {
      ic('reattach_activator')
      ic('update', w.intercomSettings)
    } else {
      const d = document
      const i = (...args) => {
        i.c(args)
      }
      i.q = []
      i.c = (args) => {
        i.q.push(args)
      }
      w.Intercom = i
      const l = () => {
        const s = d.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = `https://widget.intercom.io/widget/${process.env.REACT_APP_INTERCOM_APP_ID}`

        s.addEventListener('load', resolve)

        const x = d.getElementsByTagName('script')[0]
        x.parentNode.insertBefore(s, x)
      }
      if (document.readyState === 'complete') {
        l()
      } else if (w.attachEvent) {
        w.attachEvent('onload', l)
      } else {
        w.addEventListener('load', l, false)
      }
    }
  })
}
