/* eslint-disable react/no-danger */
import React from 'react'
import PropTypes from 'prop-types'
import { Button, Typography, Icon } from '@ahazou/react-components'
import style from './style.module.css'

const ModalAlert = ({ titleIcon, title, message, closeLabel, actionsButtons, onClose }) => {
  return (
    <div className={style.container}>
      <div className={style.content}>
        <div className={style.header}>
          {titleIcon && <Icon className={style.icon} icon={titleIcon} size="l" />}

          <Typography className={style.title} variant="displayM">
            {title}
          </Typography>
        </div>
        <Typography className={style.message} variant="bodyM" element="div">
          <p dangerouslySetInnerHTML={{ __html: message }} />
        </Typography>
      </div>

      <div className={style.actions}>
        {actionsButtons.map((buttonProps) => (
          <Button key={buttonProps.label} className={style.button} {...buttonProps} />
        ))}

        <Button type="button" className={style.button} label={closeLabel} onClick={onClose} />
      </div>
    </div>
  )
}

ModalAlert.propTypes = {
  titleIcon: PropTypes.string,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  closeLabel: PropTypes.string,
  actionsButtons: PropTypes.arrayOf(PropTypes.shape()),
  onClose: PropTypes.func.isRequired,
}

ModalAlert.defaultProps = {
  titleIcon: null,
  closeLabel: 'fechar',
  actionsButtons: [],
}

export default ModalAlert
